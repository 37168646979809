import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener,
  MenuItem,
  Box,
  Typography,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HexagonIcon from '@mui/icons-material/Hexagon';
// utils
import cssStyles from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import { Stack } from '@mui/system';
import { useStore } from 'src/store';
import { AuthState } from 'src/@types/auth';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const { auth, dispatchJWT } = useStore((store) => store as AuthState);
  const { listCompany, companyInfo } = auth.user;
  const [org, setOrg] = useState(companyInfo?.id);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const handleChange = (event: SelectChangeEvent) => {
    setOrg(event.target.value as unknown as number);
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAPICompany = (companyId: number) => {
    try {
      dispatchJWT({
        type: 'UPDATE_COMPANY',
        payload: { data: listCompany?.companies[companyId] },
      });
      if (pathname.includes('employee') || pathname.includes('jobs')) {
        const value = pathname.split('/');
        const url = `/${value[1]}/${value[2]}/list`;
        (url.includes('employee') || url.includes('jobs')) && navigate(url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <Stack>
            <Box
              sx={{
                display: 'flex',
                marginBottom: 8,
              }}
            >
              <Box sx={{ marginTop: 8 }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#637381' }}>
                  Your Organization
                </Typography>
                <Box sx={{ display: 'flex', marginTop: 1 }}>
                  <HexagonIcon color="success" sx={{ mr: 1 }} />
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={org as unknown as string}
                    label="Age"
                    onChange={handleChange}
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      '.MuiOutlinedInput-input': {
                        padding: '0px !important',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 0,
                      },
                    }}
                    IconComponent={() => <ArrowDropDownIcon />}
                  >
                    {listCompany?.companyIds?.map((id) => (
                      <MenuItem value={id} key={id} onClick={() => getAPICompany(id)}>
                        {listCompany?.companies[id].fullName}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                  marginTop: 7,
                  marginLeft: 5,
                  '&:hover': {
                    background: '#fff !important',
                  },
                }}
              >
                <Iconify icon={'eva:search-fill'} width={20} height={20} />
              </IconButtonAnimate>
            </Box>
          </Stack>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={handleClose}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
