import produce from 'immer';
import { IEmployee } from '.';
import * as types from './constants';

const employeeDetailReducer =
  (action: { type: string; payload?: any }) =>
  (state: { employee: { employee: { employees: { [x: string]: any } } } }) =>
    produce(state, (draft: { employeeDetail: IEmployee; dispatchEmployeeDetail: any }) => {
      switch (action.type) {
        case types.GET_EMPLOYEE_DETAIL_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.GET_EMPLOYEE_DETAIL_SUCCESS:
          draft.employeeDetail.personInfo = {
            ...draft.employeeDetail.personInfo,
            ...action.payload.data,
          };
          draft.employeeDetail.isLoading = false;
          break;
        case types.UPDATE_EMPLOYEE_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.UPDATE_EMPLOYEE_SUCCESS:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.personInfo = {
            ...draft.employeeDetail.personInfo,
            [action.payload.data.employeeId]: {
              ...(draft.employeeDetail.personInfo[action.payload.data.employeeId] || {}),
              passport: action.payload.data.passport,
            },
          };
          break;
        case types.UPDATE_EMPLOYEE_CONTRACT_REQUEST:
          draft.employeeDetail.isLoading = true;
          draft.employeeDetail.contract = action.payload.data;
          break;

        // Add education
        case types.ADD_EDUCATION_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.ADD_EDUCATION_SUCCESS:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.personInfo = {
            ...draft.employeeDetail.personInfo,
            [action.payload.data.employeeId]: {
              ...(draft.employeeDetail.personInfo[action.payload.data.employeeId] || {}),
              educations: action.payload.data.educations,
            },
          };
          break;
        case types.ADD_EDUCATION_FAIL:
          draft.employeeDetail.isLoading = false;
          break;

        // Language Spoken
        case types.ADD_LANGUAGE_SPOKEN_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.ADD_LANGUAGE_SPOKEN_SUCCESS:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.personInfo = {
            ...draft.employeeDetail.personInfo,
            [action.payload.data.employeeId]: {
              ...(draft.employeeDetail.personInfo[action.payload.data.employeeId] || {}),
              languageSpoken: action.payload.data.languageSpoken,
            },
          };
          break;
        case types.ADD_LANGUAGE_SPOKEN_FAIL:
          draft.employeeDetail.isLoading = false;
          break;

        // Contract Type
        case types.GET_CONTRACT_TYPE_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.GET_CONTRACT_TYPE_SUCCESS:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.contractTypes = action.payload.data;
          break;

        case types.GET_CONTRACT_DETAIL_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.GET_CONTRACT_DETAIL_SUCCESS:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.contractEmployee = action.payload.data;
          break;
        case types.GET_CONTRACT_DETAIL_FAIL:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.contractEmployee = action.payload.data;
          break;
        case types.ADD_CONTRACT_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.ADD_CONTRACT_SUCCESS:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.contractEmployee = action.payload.data;
          break;

        case types.UPDATE_CONTRACT_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.UPDATE_CONTRACT_SUCCESS:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.contractEmployee = action.payload.data;
          break;

        case types.DELETE_CONTRACT_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.DELETE_CONTRACT_SUCCESS:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.contractEmployee = action.payload.data;
          break;
        case types.DELETE_CONTRACT_FAIL:
          draft.employeeDetail.isLoading = false;
          break;
        // Payment Standards
        case types.GET_PAYMENT_STANDARDS_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.GET_PAYMENT_STANDARDS_SUCCESS:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.paymentStandards = action.payload.data;
          break;
        // Skills
        case types.GET_EMPLOYEE_SKILLS_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.GET_EMPLOYEE_SKILLS_SUCCESS:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.personInfo = {
            ...draft.employeeDetail.personInfo,
            [action.payload.data.employeeId]: {
              ...(draft.employeeDetail.personInfo[action.payload.data.employeeId] || {}),
              skills: action.payload.data.skills,
            },
          };
          break;
        case types.GET_EMPLOYEE_SKILLS_FAIL:
          draft.employeeDetail.isLoading = false;
          break;

        case types.ADD_EMPLOYEE_SKILL_REQUEST:
          draft.employeeDetail.isLoading = true;
          break;
        case types.ADD_EMPLOYEE_SKILL_SUCCESS:
          draft.employeeDetail.isLoading = false;
          draft.employeeDetail.personInfo = {
            ...draft.employeeDetail.personInfo,
            [action.payload.data.employeeId]: {
              ...(draft.employeeDetail.personInfo[action.payload.data.employeeId] || {}),
              skills: action.payload.data.skills,
            },
          };
          break;
        case types.ADD_EMPLOYEE_SKILL_FAIL:
          draft.employeeDetail.isLoading = false;
          break;
        default:
          return;
      }
    });
export default employeeDetailReducer;
