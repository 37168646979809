import { IPassport } from 'src/@types/user';
import { ILanguage } from 'src/commonServices';
import { IProfileType } from 'src/containers/Profile/store';
export interface IEducation {
  id?: number;
  institution: {
    id?: number;
    name: string;
    address: {
      countryAlpha3Code: string;
      city: string;
    };
    siteSrt?: string;
  };
  level: {
    id: number | string;
    name: string;
  };
  endingYear: number | string | Date;
  programTitle: string;
  onLine: boolean;
}

export interface IContractDetail {
  id: number;
  type: {
    id: number;
    name: string;
    comment: string;
  };
  startDate: {
    year: number;
    month: number;
    day: number;
  };
  endDate: {
    year: number;
    month: number;
    day: number;
  };
  salary: {
    amount: number;
    currency: string;
  };
  probation: {
    months: number;
    salaryPercent: number;
  };
  paymentStandard: {
    id: number;
    name: string;
    comment: string;
  };
  additionalInfo: string;
}
export interface IContractEmployee {
  id: number;
  companyId: number;
  department: number;
  contract: IContractDetail;
  personPassport: IPassport;
}
export interface IContract {
  id: number;
  startDate?: Date | null;
  endDate?: Date | null;
  amount?: number;
  contract?: string;
  periodPayment?: string;
  paymentStandard?: string;
  salaryAgreement?: true;
  type: string;
  salary?: string;
  noEndDate: boolean;
  overTime?: {
    title?: string;
    extend?: string;
    holidays?: string;
    night?: string;
    legal?: boolean;
    extend_night?: string;
    extend_holidays?: string;
    hodlidaysnight?: string;
    buttonType?: string;
  };
}

export interface IContractType {
  id: number;
  name: string;
  comment: string;
}

export interface IPaymentStandards {
  id: number;
  name: string;
  comment: string;
}
export interface IEmployee {
  personInfo: {
    [key: number | string]: IProfileType;
  };
  contract: IContract;
  isLoading: boolean;
  personLanguage: ILanguage;
  contractTypes: IContractType[];
  contractEmployee: IContractDetail;
  paymentStandards: IPaymentStandards[];
}
export interface IEmployeeDetail {
  employeeDetail: IEmployee;
  dispatchEmployeeDetail: (action: { type: string; payload?: object }) => void;
}

export const initalState = {
  personInfo: {},
  contract: {},
  isLoading: false,
  personLanguage: [],
  contractTypes: [],
  contractEmployee: {},
  paymentStandards: [],
};
