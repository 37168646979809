import { Link as RouterLink } from 'react-router-dom';
import { Fragment } from 'react';
import { Stack, Link, Typography } from '@mui/material';

import { PATH_AUTH } from 'src/routes/paths';
import LoginForm from './LoginForm';

export default function LoginContainer() {
  return (
    <Fragment>
      <Stack sx={{ mb: 5, pt: 10 }}>
        <Typography variant="h4" gutterBottom>
          Log In
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          No account?
          <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
            Join Fajna, the Future of HR
          </Link>
        </Typography>
      </Stack>
      <LoginForm />
    </Fragment>
  );
}
