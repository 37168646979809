export const GET_LIST_DEPARTMENT_REQUEST = 'GET_LIST_DEPARTMENT_REQUEST';
export const GET_LIST_DEPARTMENT_SUCCESS = 'GET_LIST_DEPARTMENT_SUCCESS';
export const GET_LIST_DEPARTMENT_FAIL = 'GET_LIST_DEPARTMENT_FAIL';

export const CREATE_DEPARTMENT_REQUEST = 'CREATE_DEPARTMENT_REQUEST';
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS';
export const CREATE_DEPARTMENT_FAIL = 'CREATE_DEPARTMENT_FAIL';

export const UPDATE_DEPARTMENT_REQUEST = 'UPDATE_DEPARTMENT_REQUEST';
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_FAIL = 'UPDATE_DEPARTMENT_FAIL';

export const DELETE_DEPARTMENT_REQUEST = 'DELETE_DEPARTMENT_REQUEST';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAIL = 'DELETE_DEPARTMENT_FAIL';