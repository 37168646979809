import produce from 'immer';
import { ICandidateReducer } from '.';
import * as types from './constants';

const candidateReducer = (action: { type: string; payload?: any }) =>
  produce((state: ICandidateReducer) => {
    switch (action.type) {
      case types.GET_CANDIDATE_LIST_SUCCESS:
        state.candidate.candidateIds = action.payload.data.cadidiateIds;
        state.candidate.candidates = action.payload.data.candidates;
        break;
      default:
        return;
    }
  });
export default candidateReducer;
