import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { PATH_AUTH } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import { FormProvider, RHFTextField, RHFCheckbox } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import { useStore } from 'src/store';
import { AuthState } from 'src/@types/auth';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login } = useAuth();
  const { auth } = useStore((state) => state as AuthState);
  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: false,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {(!!errors.afterSubmit || !!auth.error?.message) && (
          <Alert severity="error">{errors.afterSubmit?.message || auth.error?.message}</Alert>
        )}

        <RHFTextField name="email" label="Email address" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Keep me logged in" />
      </Stack>
      <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
        LOG IN
      </LoadingButton>
      <Stack sx={{ mt: 6, mb: 6 }}>
        <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.resetPassword}>
          Forgot your username or password?
        </Link>
      </Stack>
      <span style={{ fontSize: 14, color: 'grey' }}>
        By clicking Log In, I confirm that i have read and agree to the Fajna HR{' '}
        <a href=" ">Terms of Service, Privacy Policy</a> and to revice emails and updates.
      </span>
    </FormProvider>
  );
}
