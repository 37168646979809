import { createContext, ReactNode, useEffect, useState } from 'react';
import produce from 'immer';
import jwtDecode from 'jwt-decode';
// utils
import axios from '../utils/axios';
import {
  generateCodeChallengeFromVerifier,
  generateCodeVerifier,
  isValidToken,
  setSession,
} from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';
// store
import { useStore } from 'src/store';
import { VariantType } from 'notistack';
// mock

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  updateUser = 'UPDATE_USER',
  updateUserEducation = 'UPDATE_USER_EDUCATION',
  updateUserLanguageSpoken = 'UPDATE_USER_LANGUAGE_SPOKEN',
  authFail = 'AUTH_FAIL',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser | null;
  };
  [Types.Login]: {
    user: AuthUser | null;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser | null;
  };
  [Types.updateUser]: {
    user: AuthUser | null;
  };
  [Types.updateUserEducation]: {
    user: AuthUser | null;
  };
  [Types.updateUserLanguageSpoken]: {
    user: AuthUser | null;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

export const JWTReducer = (action: { type: string; payload?: any }) =>
  produce((state: AuthState) => {
    switch (action.type) {
      case 'INITIALIZE':
        state.auth.isAuthenticated = action.payload.isAuthenticated;
        state.auth.isInitialized = true;
        state.auth.user = action.payload.user;
        state.auth.error = null;
        return;
      case 'AUTH_FAIL':
        state.auth.isAuthenticated = action.payload.isAuthenticated;
        state.auth.isInitialized = true;
        state.auth.user = action.payload.user;
        state.auth.error = action.payload.error;
        return;
      case 'LOGIN':
        state.auth.isAuthenticated = true;
        state.auth.user = action.payload.user;
        return;
      case 'LOGOUT':
        state.auth.isAuthenticated = false;
        state.auth.user = action.payload;
        return;
      case 'UPDATE_USER':
        state.auth.user.personInfo.passport = action.payload.user;
        break;
      case 'UPDATE_USER_EDUCATION':
        state.auth.user.personInfo.educations = action.payload.educations;
        break;
      case 'UPDATE_USER_LANGUAGE_SPOKEN':
        state.auth.user.personInfo.languageSpoken = action.payload.languageSpoken;
        break;
      case 'CREATE_COMPANY':
        state.auth.user.companyInfo = action.payload.data;
        state.auth.user.listCompany = {
          companyIds: state.auth.user.listCompany.companyIds.concat(action.payload.data.id),
          companies: {
            ...state.auth.user.listCompany.companies,
            [action.payload.data.id]: action.payload.data,
          },
        };
        break;
      case 'UPDATE_COMPANY':
        state.auth.user.companyInfo = action.payload.data;
        break;
      default:
        return;
    }
  });

const getUserRequest = (data?: object) => ({
  type: Types.Initial,
  payload: {
    isAuthenticated: true,
    user: data,
  },
});

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const { auth, dispatchJWT } = useStore<AuthState>((store) => store as AuthState);
  const store = useStore((store) => store as { resetStore: () => {} });
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken =
          typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const { sub } = jwtDecode(accessToken) as { sub: string; role: string };

          const userInfor = await axios.get(`/api/v1/UserInfo`);

          const responsePersonInfo = await axios.get(
            `/api/v1/Person/${userInfor.data.personPassport.id}`
          );
          try {
            const responseCompanyInfo = await axios.get(`/api/v1/Company/GetWhereUserIsManager`);
            const dataCompany = responseCompanyInfo.data.companies.reduce(
              (
                obj: {
                  companyIds: number[];
                  companies: {
                    [key: number]: object;
                  };
                },
                item: { id: number }
              ) => {
                obj.companyIds.push(item.id);
                obj.companies = {
                  ...obj.companies,
                  [item.id]: item,
                };
                return obj;
              },
              {
                companyIds: [],
                companies: {},
              }
            );
            const data = {
              // userdata: response.data,
              personInfo: responsePersonInfo.data,
              companyInfo: responseCompanyInfo.data.companies[0],
              listCompany: dataCompany,
            };
            dispatchJWT(getUserRequest(data));
          } catch (error) {
            const data = {
              // userdata: response.data,
              personInfo: responsePersonInfo.data,
              companyInfo: {},
              listCompany: {
                companyIds: [],
                companies: {},
              },
            };
            dispatchJWT(getUserRequest(data));
          }

          setIsLoginSuccessful(false);
        } else {
          dispatchJWT({
            type: Types.authFail,
            payload: {
              isAuthenticated: false,
              user: {
                userData: {},
                personInfo: {},
                companyInfo: {},
              },
            },
          });
        }
      } catch (err) {
        console.log(err);
        const payload = { ...err };
        if (payload.status === 401) {
          return dispatchJWT({
            type: Types.authFail,
            payload: {
              isAuthenticated: false,
              user: {
                userData: {},
                personInfo: {},
                companyInfo: {},
              },
            },
          });
        }
        return dispatchJWT({
          type: Types.authFail,
          payload: {
            isAuthenticated: true,
            user: {
              userData: {},
              personInfo: {},
              companyInfo: {},
            },
          },
        });
      }
    };

    initialize();
  }, [dispatchJWT, isLoginSuccessful]);

  const login = async (email: string, password: string) => {
    try {
      await axios.post('api/v1/Account/Login', {
        username: email,
        password,
        rememberLogin: true,
        returnUrl: 'string',
      });
      const codeVerifier = generateCodeVerifier();
      const params = {
        client_id: 'fajna-web-test',
        redirect_uri: 'https://fajna.digitalfortress.dev/',
        response_type: 'code',
        scope: 'scope-code-fajna',
        code_challenge: await generateCodeChallengeFromVerifier(codeVerifier),
        code_challenge_method: 'S256',
        response_mode: 'form_post',
      };
      const response = await axios.get(
        `/connect/authorize?response_type=code&client_id=${params.client_id}&scope=scope-code-fajna&redirect_uri=${params.redirect_uri}&code_challenge=${params.code_challenge}&code_challenge_method=S256&response_mode=${params.response_mode}`
      );
      const [, code] = response.data.match(/value='(.*)'/);
      const { data } = await axios.post(
        '/connect/token',
        {
          grant_type: 'authorization_code',
          code,
          redirect_uri: params.redirect_uri,
          code_verifier: codeVerifier,
          client_id: params.client_id,
          client_secret: 'A very secret string that must be passed to the front end application.',
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      setSession(data.access_token || '');
      setIsLoginSuccessful(true);
      // dispatchJWT({
      //   type: Types.Login,
      //   payload: {
      //     isAuthenticated: true,
      //     user: {
      //       userData: {},
      //       personInfo: { email },
      //       companyInfo: {},
      //     },
      //   },
      // });
    } catch (error) {
      dispatchJWT({
        type: Types.authFail,
        payload: {
          isAuthenticated: false,
          user: {
            userData: {},
            personInfo: {},
            companyInfo: {},
          },
          error: { ...error.data },
        },
      });
    }
  };

  const register = async (email: string, password: string) => {
    try {
      await axios.post('/api/v1/Account/Register', {
        email,
        password,
      });
      sendEmailConfirm(email);
      sessionStorage.setItem('email-register', email);
    } catch (error) {
      dispatchJWT({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const sendEmailConfirm = async (email: string) => {
    try {
      await axios.post(`/api/v1/Account/Register/SendConfirmMessage`, {
        email,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const logout = async () => {
    localStorage.removeItem('accessToken');
    setSession(null);
    dispatchJWT({ type: Types.Logout, payload: {} });
    store.resetStore();
  };
  // password
  const sendResetEmail = async (email: string) => {
    try {
      await axios.post(`/api/v1/Account/Password/SendResetEmail`, {
        email,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const resetAccount = async (data: object) => {
    try {
      await axios.post(`/api/v1/Account/Password/Reset`, data);
    } catch (error) {
      console.log(error);
    }
  };

  const createUserInfor = async (
    userInfor: object,
    callback?: (message: string, status: VariantType) => void
  ) => {
    try {
      const res = await axios.post(`/api/v1/UserInfo`, userInfor);
      const data = {
        personInfo: res.data,
        companyInfo: [],
        listCompany: [],
      };
      dispatchJWT(getUserRequest(data));
      callback && callback('Create new User Information Successed!', 'success');
    } catch (error) {
      callback && callback(error.data.message, 'error');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        method: 'jwt',
        login,
        logout,
        register,
        sendResetEmail,
        resetAccount,
        createUserInfor,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
