export const GET_JOB_DETAIL_REQUEST = 'GET_JOB_DETAIL_REQUEST';
export const GET_JOB_DETAIL_SUCCESS = 'GET_JOB_DETAIL_SUCCESS';
export const GET_JOB_DETAIL_FAIL = 'GET_JOB_DETAIL_FAIL';

export const UPDATE_JOB_DETAIL_REQUEST = 'UPDATE_JOB_DETAIL_REQUEST';
export const UPDATE_JOB_DETAIL_SUCCESS = 'UPDATE_JOB_DETAIL_SUCCESS';
export const UPDATE_JOB_DETAIL_FAIL = 'UPDATE_JOB_DETAIL_FAIL';

export const UPDATE_STAGE_REQUEST = 'UPDATE_STAGE_REQUEST';
export const UPDATE_STAGE_SUCCESS = 'UPDATE_STAGE_SUCCESS';
export const UPDATE_STAGE_FAIL = 'UPDATE_STAGE_FAIL';

// Candidate
export const CREATE_CANDIDATE_REQUEST = 'CREATE_CANDIDATE_REQUEST';
export const CREATE_CANDIDATE_SUCCESS = 'CREATE_CANDIDATE_SUCCESS';
export const CREATE_CANDIDATE_FAIL = 'CREATE_CANDIDATE_FAIL';

export const DELETE_CANDIDATE_REQUEST = 'DELETE_CANDIDATE_REQUEST';
export const DELETE_CANDIDATE_SUCCESS = 'DELETE_CANDIDATE_SUCCESS';
export const DELETE_CANDIDATE_FAIL = 'DELETE_CANDIDATE_FAIL';
// Vacancy
export const GET_VACANCY_DETAIL_REQUEST = 'GET_VACANCY_DETAIL_REQUEST';
export const GET_VACANCY_DETAIL_SUCCESS = 'GET_VACANCY_DETAIL_SUCCESS';
export const GET_VACANCY_DETAIL_FAIL = 'GET_VACANCY_DETAIL_FAIL';

// Update Vacancy

export const UPDATE_VACANCY_REQUEST = 'UPDATE_VACANCY_REQUEST';
export const UPDATE_VACANCY_SUCCESS = 'UPDATE_VACANCY_SUCCESS';
export const UPDATE_VACANCY_FAIL = 'UPDATE_VACANCY_FAIL';

// Vancay skills
export const ADD_KEY_SKILL_REQUEST = 'ADD_KEY_SKILL_REQUEST';
export const ADD_KEY_SKILL_SUCCESS = 'ADD_KEY_SKILL_SUCCESS';
export const ADD_KEY_SKILL_FAIL = 'ADD_KEY_SKILL_FAIL';

export const ADD_ADTIONAL_SKILL_REQUEST = 'ADD_ADTIONAL_SKILL_REQUEST';
export const ADD_ADTIONAL_SKILL_SUCCESS = 'ADD_ADTIONAL_SKILL_SUCCESS';
export const ADD_ADTIONAL_SKILL_FAIL = 'ADD_ADTIONAL_SKILL_FAIL';

export const GET_VACANCY_SKILL_REQUEST = 'GET_VACANCY_SKILL_REQUEST';
export const GET_VACANCY_SKILL_SUCCESS = 'GET_VACANCY_SKILL_SUCCESS';
export const GET_VACANCY_SKILL_FAIL = 'GET_VACANCY_SKILL_FAIL';

export const UPDATE_VACANCY_SKILL_REQUEST = 'UPDATE_VACANCY_SKILL_REQUEST';
export const UPDATE_VACANCY_SKILL_SUCCESS = 'UPDATE_VACANCY_SKILL_SUCCESS';
export const UPDATE_VACANCY_SKILL_FAIL = 'UPDATE_VACANCY_SKILL_FAIL';

// Hiring
export const GET_HIRING_REQUEST = 'GET_HIRING_REQUEST';
export const GET_HIRING_SUCCESS = 'GET_HIRING_SUCCESS';
export const GET_HIRING_FAIL = 'GET_HIRING_FAIL';

// Recourse
export const GET_RECOURSE_REQUEST = 'GET_RECOURSE_REQUEST';
export const GET_RECOURSE_SUCCESS = 'GET_RECOURSE_SUCCESS';
export const GET_RECOURSE_FAIL = 'GET_RECOURSE_FAIL';

//Rating
export const UPDADTE_RATING_REQUEST = 'UPDADTE_RATING_REQUEST';
export const UPDADTE_RATING_SUCCESS = 'UPDADTE_RATING_SUCCESS';
export const UPDADTE_RATING_FAIL = 'UPDADTE_RATING_FAIL';
