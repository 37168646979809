import produce from 'immer';
import { IEmployeeReducer } from './index';
import * as types from './constants';

const employeeReducer = (action: { type: string; payload?: any }) =>
  produce((state: IEmployeeReducer) => {
    switch (action.type) {
      case types.GET_LIST_EMPLOYEES_REQUEST:
        state.employee.isLoading = true;
        break;
      case types.GET_LIST_EMPLOYEES_SUCCESS:
        state.employee.employeeIds = action.payload.data.employee.employeeIds;
        state.employee.employees = action.payload.data.employee.employees;
        state.employee.employeeInfoById = action.payload.data.employeeInfoById;
        state.employee.isLoading = false;
        break;
      case types.GET_LIST_EMPLOYEES_FAIL:
        state.employee.isLoading = false;
        state.employee.employees = {};
        state.employee.employeeIds = [];
        break;
      case types.CREATE_NEW_EMPLOYEE_REQUEST:
        state.employee.isLoading = true;
        break;
      case types.CREATE_NEW_EMPLOYEE_SUCCESS:
        state.employee.employeeIds = [
          ...state.employee.employeeIds,
          action.payload.data.employee.id,
        ];
        state.employee.employees = {
          ...state.employee.employees,
          [action.payload.data.employee.id]: action.payload.data.employee,
        };
        state.employee.employeeInfoById = {
          ...state.employee.employeeInfoById,
          [action.payload.data.employeeInfoById.personId]: action.payload.data.employeeInfoById,
        };
        state.employee.isLoading = false;
        break;
      case types.CREATE_NEW_EMPLOYEE_FAIL:
        state.employee.isLoading = false;
        break;

      case types.DELETE_EMPLOYEE_REQUEST:
        state.employee.isLoading = true;
        break;
      case types.DELETE_EMPLOYEE_SUCCESS:
        const deletedata = state.employee.employeeIds.filter((item) => item !== action.payload.id);
        //@ts-ignore
        delete state.employee.employees[action.payload.id];
        state.employee.isLoading = false;
        state.employee.employeeIds = deletedata;
        break;
      case types.DELETE_LIST_EMPLOYEE_REQUEST:
        state.employee.isLoading = true;
        break;
      case types.DELETE_LIST_EMPLOYEE_SUCCESS:
        const deleteList = state.employee.employeeIds.filter(
          (row) => !action.payload.id.includes(row)
        );
        state.employee.isLoading = false;
        state.employee.employeeIds = deleteList;
        //@ts-ignore
        action.payload.id.map((num: number) => delete state.employee.employee.employees[num]);
        break;
      case types.DELETE_LIST_EMPLOYEE_FAIL:
        state.employee.isLoading = false;
        break;

      // Department
      case types.UPDATE_EMPLOYEE_DEPARTMENT_REQUEST:
        state.employee.isLoading = true;
        break;
      case types.UPDATE_EMPLOYEE_DEPARTMENT_SUCCESS:
        state.employee.isLoading = false;
        state.employee.employeeInfoById = {
          ...state.employee.employeeInfoById,
          [action.payload.data.personId]: action.payload.data,
        };
        break;
      case types.UPDATE_EMPLOYEE_DEPARTMENT_FAIL:
        state.employee.isLoading = false;
        break;

      //Update Employee Status
      case types.UPDATE_EMPLOYEE_STATUS_REQUEST:
        state.employee.isLoading = true;
        break;
      case types.UPDATE_EMPLOYEE_STATUS_SUCCESS:
        state.employee.isLoading = false;
        state.employee.employeeInfoById = {
          ...state.employee.employeeInfoById,
          [action.payload.data.personId]: action.payload.data,
        };
        break;
      case types.UPDATE_EMPLOYEE_STATUS_FAIL:
        state.employee.isLoading = false;
        break;

      // Get Employee Status
      case types.GET_STATUS_EMPLOYEE_REQUEST:
        state.employee.isLoading = true;
        break;
      case types.GET_STATUS_EMPLOYEE_SUCCESS:
        state.employee.isLoading = true;
        state.employee.employeeStatus = action.payload.data;
        break;
      case types.GET_STATUS_EMPLOYEE_FAIL:
        state.employee.isLoading = true;
        break;

      // Create Employee Job
      case types.CREATE_EMPLOYEE_JOB_SUCCESS:
        state.employee.employeeInfoById = {
          ...state.employee.employeeInfoById,
          [action.payload.data.employeeId]: {
            ...state.employee.employeeInfoById[action.payload.data.employeeId],
            vacancyId: action.payload.data.vacancyId,
          },
        };
        break;
      default:
        return;
    }
  });
export default employeeReducer;
