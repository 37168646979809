export const GET_LIST_EMPLOYEES_REQUEST = 'employee/GET_LIST_EMPLOYEES_REQUEST';
export const GET_LIST_EMPLOYEES_SUCCESS = 'employee/GET_LIST_EMPLOYEES_SUCCESS';
export const GET_LIST_EMPLOYEES_FAIL = 'employee/GET_LIST_EMPLOYEES_FAIL';

export const DELETE_EMPLOYEE_REQUEST = 'employee/DELETE_EMPLOYEE_REQUEST';
export const DELETE_EMPLOYEE_SUCCESS = 'employee/DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAIL = 'employee/DELETE_EMPLOYEE_FAIL';

export const CREATE_NEW_EMPLOYEE_REQUEST = 'employee/CREATE_NEW_EMPLOYEE_REQUEST';
export const CREATE_NEW_EMPLOYEE_SUCCESS = 'employee/CREATE_NEW_EMPLOYEE_SUCCESS';
export const CREATE_NEW_EMPLOYEE_FAIL = 'employee/CREATE_NEW_EMPLOYEE_FAIL';

export const DELETE_LIST_EMPLOYEE_REQUEST = 'employee/DELETE_LIST_EMPLOYEE_REQUEST';
export const DELETE_LIST_EMPLOYEE_SUCCESS = 'employee/DELETE_LIST_EMPLOYEE_SUCCESS';
export const DELETE_LIST_EMPLOYEE_FAIL = 'employee/DELETE_LIST_EMPLOYEE_FAIL';

// Skills
export const CREATE_EMPLOYEE_SKILL_REQUEST = 'employee/CREATE_EMPLOYEE_SKILL_REQUEST';
export const CREATE_EMPLOYEE_SKILL_SUCCESS = 'employee/CREATE_EMPLOYEE_SKILL_SUCCESS';
export const CREATE_EMPLOYEE_SKILL_FAIL = 'employee/CREATE_EMPLOYEE_SKILL_FAIL';

export const GET_EMPLOYEE_SKILL_REQUEST = 'employee/GET_EMPLOYEE_SKILL_REQUEST';
export const GET_EMPLOYEE_SKILL_SUCCESS = 'employee/GET_EMPLOYEE_SKILL_SUCCESS';
export const GET_EMPLOYEE_SKILL_FAIL = 'employee/GET_EMPLOYEE_SKILL_FAIL';

export const UPDATE_EMPLOYEE_SKILL_REQUEST = 'employee/UPDATE_EMPLOYEE_SKILL_REQUEST';
export const UPDATE_EMPLOYEE_SKILL_SUCCESS = 'employee/UPDATE_EMPLOYEE_SKILL_SUCCESS';
export const UPDATE_EMPLOYEE_SKILL_FAIL = 'employee/UPDATE_EMPLOYEE_SKILL_FAIL';

// Emplpoyee Depart
export const UPDATE_EMPLOYEE_DEPARTMENT_REQUEST = 'UPDATE_EMPLOYEE_DEPARTMENT_REQUEST';
export const UPDATE_EMPLOYEE_DEPARTMENT_SUCCESS = 'UPDATE_EMPLOYEE_DEPARTMENT_SUCCESS';
export const UPDATE_EMPLOYEE_DEPARTMENT_FAIL = 'UPDATE_EMPLOYEE_DEPARTMENT_FAIL';

// Employee status
export const GET_STATUS_EMPLOYEE_REQUEST = 'GET_STATUS_EMPLOYEE_REQUEST';
export const GET_STATUS_EMPLOYEE_SUCCESS = 'GET_STATUS_EMPLOYEE_SUCCESS';
export const GET_STATUS_EMPLOYEE_FAIL = 'GET_STATUS_EMPLOYEE_FAIL';

// Update Employee Status
export const UPDATE_EMPLOYEE_STATUS_REQUEST = 'UPDATE_EMPLOYEE_STATUS_REQUEST';
export const UPDATE_EMPLOYEE_STATUS_SUCCESS = 'UPDATE_EMPLOYEE_STATUS_SUCCESS';
export const UPDATE_EMPLOYEE_STATUS_FAIL = 'UPDATE_EMPLOYEE_STATUS_FAIL';

// Employee job
export const CREATE_EMPLOYEE_JOB_SUCCESS = 'CREATE_EMPLOYEE_JOB_SUCCESS';
