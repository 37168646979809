// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { CalendarPickerView, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  view?: CalendarPickerView[];
  disabled?: boolean;
  maxDate?: Date;
};

type Props = IProps & TextFieldProps;

export default function RHFDatePicker({
  name,
  label,
  size,
  disabled,
  view = ['day', 'month', 'year'],
  maxDate
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={view}
            label={label}
            value={field.value}
            maxDate={maxDate}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            disabled={!!disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={!!error}
                size={size || 'medium'}
                helperText={error?.message}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
}
