// form
import 'react-phone-input-2/lib/material.css';

import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { styled, TextFieldProps } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import parsePhoneNumberFromString from 'libphonenumber-js';

// ----------------------------------------------------------------------
const PhoneInputComp = styled(PhoneInput)(({ theme }) => ({}));

type IProps = {
  name: string;
  height?: string;
};

type Props = IProps & TextFieldProps;

export default function RHFPhoneInput({ name, height }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <PhoneInputComp
          specialLabel=""
          enableSearch
          enableAreaCodes
          {...field}
          value={field.value}
          isValid={(value) => parsePhoneNumberFromString(`+${value}`)?.isValid() || false}
          inputProps={{
            name: 'phone',
            required: true,
          }}
          sx={
            parsePhoneNumberFromString(`+${field.value}`)?.isValid()
              ? {
                  '.form-control:focus': {
                    borderColor: '#4BB543 !important',
                    boxShadow: '0px 0px 0px 1px #4BB543 !important',
                  },
                  '.flag-dropdown': {
                    height: height,
                  },
                }
              : {}
          }
          inputStyle={{
            height: height,
            width: '100%',
          }}
        />
      )}
    />
  );
}
