import { ActionTypes } from 'src/interfaces';

export interface IDepartmentType {
  number: number;
  name: string;
  status: boolean;
}
export interface IDepartment {
  department: {
    departments: {
      [key: number | string]: IDepartmentType;
    };
    departmentIds: number[];
    isLoading: boolean;
  };
  dispatchDepartment: (action: ActionTypes) => void;
}

export const initialState = {
  departmentIds: [],
  departments: {},
  isLoading: false,
};
