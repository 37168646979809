import { ReactNode, useState } from 'react';
import { Box, Container, Dialog, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AuthState } from 'src/@types/auth';
// components
import AuthGuard from 'src/guards/AuthGuard';
import DashboardLayout from './dashboard';
import LogoOnlyLayout from './LogoOnlyLayout';
import CreateCompany from 'src/containers/Company/CreateCompany';
import { useStore } from 'src/store';
import UserInforForm from 'src/containers/UserInfo/FormCreateUserInfo';

const DialogStyle = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 700,
    maxWidth: '100%',
  },
}));

// ----------------------------------------------------------------------
type Props = {
  children?: ReactNode;
  variant?: 'main' | 'dashboard' | 'logoOnly';
};

export default function Layout({ variant = 'dashboard' }: Props) {
  const { auth } = useStore((store) => store as AuthState);
  const { user } = auth;
  const [open, setOpen] = useState(!Object.values(user?.companyInfo || {}).length);
  // const [valueUserInfo, setValueUserInfo] = useState();

  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  if (variant === 'logoOnly') {
    return <LogoOnlyLayout />;
  }
  return (
    <AuthGuard>
      {Object.keys(user.personInfo).length === 0 ? (
        <Container sx={{ m: 15 }}>
          <UserInforForm />
        </Container>
      ) : (
        <Box>
          <DashboardLayout />
          <DialogStyle open={open}>
            <DialogTitle>Please Enter Your Company Infomation</DialogTitle>
            <CreateCompany onClose={handleClose} />
          </DialogStyle>
        </Box>
      )}
    </AuthGuard>
  );
}
