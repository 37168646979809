export const GET_EMPLOYEE_DETAIL_REQUEST = 'employeeDtails/GET_EMPLOYEE_DETAIL_REQUEST';
export const GET_EMPLOYEE_DETAIL_SUCCESS = 'employeeDtails/GET_EMPLOYEE_DETAIL_SUCCESS';
export const GET_EMPLOYEE_DETAIL_FAIL = 'employeeDtails/GET_EMPLOYEE_DETAIL_FAIL';

export const UPDATE_EMPLOYEE_REQUEST = 'employeeDtails/UPDATE_EMPLOYEE_REQUEST';
export const UPDATE_EMPLOYEE_SUCCESS = 'employeeDtails/UPDATE_EMPLOYEE_SUCCESS ';

export const UPDATE_EMPLOYEE_CONTRACT_REQUEST = 'employeeDetail/UPDATE_EMPLOYEE_CONTRACT_REQUEST';
export const UPDATE_EMPLOYEE_CONTRACT_SUCCESS = 'employeeDetail/UPDATE_EMPLOYEE_CONTRACT_SUCCESS';

// Skills
export const GET_EMPLOYEE_SKILLS_REQUEST = 'GET_EMPLOYEE_SKILLS_REQUEST';
export const GET_EMPLOYEE_SKILLS_SUCCESS = 'GET_EMPLOYEE_SKILLS_SUCCESS';
export const GET_EMPLOYEE_SKILLS_FAIL = 'GET_EMPLOYEE_SKILLS_FAIL';

export const ADD_EMPLOYEE_SKILL_REQUEST = 'ADD_EMPLOYEE_SKILL_REQUEST';
export const ADD_EMPLOYEE_SKILL_SUCCESS = 'ADD_EMPLOYEE_SKILL_SUCCESS';
export const ADD_EMPLOYEE_SKILL_FAIL = 'ADD_EMPLOYEE_SKILL_FAIL';

export const DELETE_EMPLOYEE_SKILL_REQUEST = 'DELETE_EMPLOYEE_SKILL_REQUEST';
export const DELETE_EMPLOYEE_SKILL_SUCCESS = 'DELETE_EMPLOYEE_SKILL_SUCCESS';
export const DELETE_EMPLOYEE_SKILL_FAIL = 'DELETE_EMPLOYEE_SKILL_FAIL';

// Language Spoken

export const ADD_LANGUAGE_SPOKEN_REQUEST = 'ADD_LANGUAGE_SPOKEN_REQUEST';
export const ADD_LANGUAGE_SPOKEN_SUCCESS = 'ADD_LANGUAGE_SPOKEN_SUCCESS';
export const ADD_LANGUAGE_SPOKEN_FAIL = 'ADD_LANGUAGE_SPOKEN_FAIL';

export const DELETE_LANGUAGE_SPOKEN_REQUEST = 'DELETE_LANGUAGE_SPOKEN_REQUEST';
export const DELETE_LANGUAGE_SPOKEN_SUCCESS = 'DELETE_LANGUAGE_SPOKEN_SUCCESS';
export const DELETE_LANGUAGE_SPOKEN_FAIL = 'DELETE_LANGUAGE_SPOKEN_FAIL';

// Education

export const ADD_EDUCATION_REQUEST = 'ADD_EDUCATION_REQUEST';
export const ADD_EDUCATION_SUCCESS = 'ADD_EDUCATION_SUCCESS';
export const ADD_EDUCATION_FAIL = 'ADD_EDUCATION_FAIL';

export const DELETE_EDUCATION_REQUEST = 'DELETE_EDUCATION_REQUEST';
export const DELETE_EDUCATION_SUCCESS = 'DELETE_EDUCATION_SUCCESS';
export const DELETE_EDUCATION_FAIL = 'DELETE_EDUCATION_FAIL';

// Contract
export const GET_CONTRACT_TYPE_REQUEST = 'GET_CONTRACT_TYPE_REQUEST';
export const GET_CONTRACT_TYPE_SUCCESS = 'GET_CONTRACT_TYPE_SUCCESS';
export const GET_CONTRACT_TYPE_FAIL = 'GET_CONTRACT_TYPE_FAIL';

export const GET_CONTRACT_DETAIL_REQUEST = 'GET_CONTRACT_DETAIL_REQUEST';
export const GET_CONTRACT_DETAIL_SUCCESS = 'GET_CONTRACT_DETAIL_SUCCESS';
export const GET_CONTRACT_DETAIL_FAIL = 'GET_CONTRACT_DETAIL_FAIL';

export const ADD_CONTRACT_REQUEST = 'ADD_CONTRACT_REQUEST';
export const ADD_CONTRACT_SUCCESS = 'ADD_CONTRACT_SUCCESS';
export const ADD_CONTRACT_FAIL = 'ADD_CONTRACT_FAIL';

export const UPDATE_CONTRACT_REQUEST = 'UPDATE_CONTRACT_REQUEST';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_FAIL = 'UPDATE_CONTRACT_FAIL';

export const DELETE_CONTRACT_REQUEST = 'DELETE_CONTRACT_REQUEST';
export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS';
export const DELETE_CONTRACT_FAIL = 'DELETE_CONTRACT_FAIL';

// Paymetn Standar
export const GET_PAYMENT_STANDARDS_REQUEST = 'GET_PAYMENT_STANDARDS_REQUEST';
export const GET_PAYMENT_STANDARDS_SUCCESS = 'GET_PAYMENT_STANDARDS_SUCCESS';
export const GET_PAYMENT_STANDARDS_FAIL = 'GET_PAYMENT_STANDARDS_FAIL';
