import { IPassport, UserType } from 'src/@types/user';
import { ActionTypes } from 'src/interfaces';

export interface IInterviewMethod {
  id: number;
  name: string;
  comment: string;
}

export interface IInterview {
  id: number;
  candidates: number[];
  interwiewers: number[];
  method: {
    id: number;
    name: string;
    comment: string;
  };
  type: {
    id: number;
    name: string;
    comment: string;
  };
  start: string;
  durationMinutes: number;
  place: string;
}
export interface ICandidate {
  state: any;
  candidateDetail: {
    candidate: {
      passport: IPassport;
      skills: { category: string; title: string; id: number }[];
      userType: UserType;
    };
    hiring: {
      notes: string;
      rating: string;
    };
    interviewMethod: IInterviewMethod[];
    interviewType: IInterviewMethod[];
    interview: {
      [id: string]: IInterview;
    };
    interviewIds: number[];
    isLoading: boolean;
  };
  dispatchCandidateDetail: (action: ActionTypes) => void;
}
export const initialState = {
  isLoading: false,
  candidate: {},
  hiring: {},
  interviewMethod: [],
  interviewType: [],
  interview: {},
  interviewIds: [],
};
