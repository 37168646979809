import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { ActionTypes } from 'src/interfaces';
import { JWTReducer } from 'src/contexts/JWTContext';
// inital
import { initialState as dashboardStore } from 'src/containers/Dashboard/store';
import { initialState as jobStore } from 'src/containers/Job/store';
import { initialState as jobDetailStore } from 'src/containers/JobDetail/store';
import { initialState as candidateDetailStore } from 'src/containers/Candidate/store';
import { initialState as companyStore } from 'src/containers/Company/store';
import { initialState as departmentStore } from 'src/containers/Department/store';
import { initialState as employeeStore } from 'src/containers/Employee/store';
import { initalState as employeeDetailStore } from 'src/containers/EmployeeDetail/store';
import { initalState as profileStore } from 'src/containers/Profile/store';
import { initalState as commonStore } from 'src/commonServices/index';
import { initialState as candidateStore } from 'src/containers/Candidates/store';
// reducer
import companyReducer from 'src/containers/Company/store/reducer';
import dashboardReducer from 'src/containers/Dashboard/store/reducer';
import JobReducer from 'src/containers/Job/store/reducer';
import jobDetailReducer from 'src/containers/JobDetail/store/reducer';
import candidateDetailReducer from 'src/containers/Candidate/store/reducer';
import departmentReducer from 'src/containers/Department/store/reducer';
import employeeReducer from 'src/containers/Employee/store/reducer';
import employeeDetailReducer from 'src/containers/EmployeeDetail/store/reducer';
import profileReducer from 'src/containers/Profile/store/reducer';
import ICommonReducer from 'src/commonServices/reducer';
import candidateReducer from 'src/containers/Candidates/store/reducer';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export const useStore = create(
  devtools(
    (set) => ({
      //State
      auth: initialState,
      dashboard: dashboardStore,
      employee: employeeStore,
      employeeDetail: employeeDetailStore,
      jobDetail: jobDetailStore,
      candidateDetail: candidateDetailStore,
      job: jobStore,
      company: companyStore,
      profile: profileStore,
      department: departmentStore,
      commonServices: commonStore,
      candidate: candidateStore,

      //reducer
      dispatchJWT: (action: ActionTypes) => set(JWTReducer(action), false, action),
      dispatchDashboard: (action: ActionTypes) => set(dashboardReducer(action), false, action),
      dispatchEmployee: (action: ActionTypes) => set(employeeReducer(action), false, action),
      dispatchEmployeeDetail: (action: ActionTypes) =>
        set(employeeDetailReducer(action), false, action),
      dispatchJobDetail: (action: ActionTypes) => set(jobDetailReducer(action), false, action),
      dispatchCandidateDetail: (action: ActionTypes) =>
        set(candidateDetailReducer(action), false, action),
      dispatchJob: (action: ActionTypes) => set(JobReducer(action), false, action),
      dispatchCompany: (action: ActionTypes) => set(companyReducer(action), false, action),
      dispatchProfile: (action: ActionTypes) => set(profileReducer(action), false, action),
      dispatchDepartment: (action: ActionTypes) => set(departmentReducer(action), false, action),
      dispatchCommon: (action: ActionTypes) => set(ICommonReducer(action), false, action),
      dispatchCandidates: (action: ActionTypes) => set(candidateReducer(action), false, action),
      resetStore: () => set({}),
    }),
    { enabled: window.location.host.includes('localhost:') }
  )
);
