import { IEducation, IPassport } from 'src/@types/user';
import { ILanguage } from 'src/commonServices';

export interface IProfileType {
  passport: IPassport;
  //will change any to type of arrays
  educations: IEducation[];
  languageSpoken: ILanguage[];
  skills: { category: string; title: string; id: number | string }[];
  socialAccounts: any[];
  edutcation: IEducation;
  //----------
  job: string;
  date: Date | string;
  status: string;
  employment: string;
  department: string;
  avatar: string;
}
export interface IProfile {
  profile: {
    userInfor: IProfileType;
    isLoading: boolean;
  };
  dispatchProfile: (action: { type: string; payload?: any }) => void;
}

export const initalState = {
  userInfor: {},
  isLoading: false,
};
