import produce from 'immer';
import { IJobReducer } from '.';
import * as types from './constants';

const JobReducer = (action: { type: string; payload?: any }) =>
  produce((state: IJobReducer) => {
    switch (action.type) {
      case types.GET_LIST_JOB_REQUEST:
        state.job.isLoading = true;
        break;
      case types.GET_LIST_JOB_SUCCESS:
        state.job.isLoading = false;
        state.job.jobs = action.payload.data.jobs;
        state.job.jobIds = action.payload.data.jobIds;
        break;
      case types.GET_LIST_JOB_FAIL:
        state.job.isLoading = false;
        state.job.jobs = {};
        state.job.jobIds = [];
        break;
      case types.ADD_NEW_JOB_REQUEST:
        state.job.isLoading = true;
        break;
      case types.ADD_NEW_JOB_SUCCESS:
        state.job.jobs = {
          ...state.job.jobs,
          [action.payload.data.id]: action.payload.data,
        };
        state.job.isLoading = false;
        break;
      case types.ADD_NEW_JOB_FAIL:
        state.job.isLoading = false;
        break;
      case types.DELETE_JOB_REQUEST:
        state.job.isLoading = true;
        break;
      case types.DELETE_JOB_SUCCESS:
        state.job.isLoading = false;

        state.job.jobIds = state.job.jobIds.filter((id) => id !== action.payload.id);
        delete state.job.jobs[action.payload.id];
        break;
      case types.DELETE_JOB_FAIL:
        state.job.isLoading = false;
        break;
      // Vacancy
      case types.GET_VACANCY_REQUEST:
        state.job.isLoading = true;
        break;
      case types.GET_VACANCY_SUCCESS:
        state.job.isLoading = false;
        state.job.vacancyIds = action.payload.data.vacancyIds;
        state.job.vacancies = action.payload.data.vacancies;
        break;
      case types.DELETE_VACANCY_SUCCESS:
        state.job.vacancyIds = state.job.vacancyIds.filter((id) => id !== action.payload.id);
        break;
      case types.UPDATE_VACANCY_SUCCESS:
        state.job.vacancies = {
          ...state.job.vacancies,
          [action.payload.data.id]: action.payload.data,
        };
        state.job.isLoading = false;
        break;
      case types.GET_VACANCY_FAIL:
        state.job.isLoading = false;
        state.job.vacancyIds = [];
        state.job.vacancies = [];
        break;

      case types.ADD_VACANCY_REQUEST:
        state.job.isLoading = true;
        break;
      case types.ADD_VACANCY_SUCCESS:
        state.job.isLoading = false;
        state.job.vacancyIds = [...state.job.vacancyIds, action.payload.data.id];
        state.job.vacancies = {
          ...state.job.vacancies,
          [action.payload.data.id]: action.payload.data,
        };
        break;
      case types.ADD_VACANCY_FAIL:
        state.job.isLoading = false;
        break;

      // Work Schedule
      case types.GET_WORKSCHEDULE_REQUEST:
        state.job.isLoading = true;
        break;
      case types.GET_WORKSCHEDULE_SUCCESS:
        state.job.isLoading = false;
        state.job.workSchedule = action.payload.data;
        break;
      case types.GET_WORKSCHEDULE_FAIL:
        state.job.isLoading = false;
        break;

      // Get Status
      case types.GET_JOB_STATUS_REQUEST:
        state.job.isLoading = true;
        break;
      case types.GET_JOB_STATUS_SUCCESS:
        state.job.isLoading = false;
        state.job.status = action.payload.data;
        break;
      case types.GET_JOB_STATUS_FAIL:
        state.job.isLoading = false;
        break;

      // Update Status
      case types.UPDATE_JOB_STATUS_REQUEST:
        state.job.isLoading = true;
        break;
      case types.UPDATE_JOB_STATUS_SUCCESS:
        state.job.isLoading = false;
        state.job.jobs = {
          ...state.job.jobs,
          [action.payload.data.id]: {
            ...state.job.jobs[action.payload.data.id],
            statusId: action.payload.data.statusId,
          },
        };
        break;
      case types.UPDATE_JOB_STATUS_FAIL:
        state.job.isLoading = false;
        break;
      default:
        return;
    }
  });
export default JobReducer;
