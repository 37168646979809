import produce from 'immer';
import { ActionTypes } from 'src/interfaces';
import { ICommon } from '.';
import * as types from './constants';

const ICommonReducer = (action: ActionTypes) =>
  produce((state: ICommon) => {
    switch (action.type) {
      case types.GET_SKILL_CATEGORIES_SUCCESS:
        state.commonServices.categoryIds = action.payload.data.categoryIds;
        state.commonServices.categories = action.payload.data.categories;
        state.commonServices.isLoading = false;
        break;
      case types.GET_ALL_SKILLS_FROM_CATEGORY_REQUEST:
        state.commonServices.isLoading = true;
        break;
      case types.GET_ALL_SKILLS_FROM_CATEGORY_SUCCESS:
        state.commonServices.skillsByCategory = {
          ...state.commonServices.skillsByCategory,
          ...action.payload.data,
        };
        state.commonServices.isLoading = false;
        break;
      case types.GET_ALL_SKILLS_FROM_CATEGORY_FAIL:
        state.commonServices.isLoading = false;
        break;
      case types.ADD_SKILL:
        state.commonServices.skillsByCategory[action.payload.categoryId] = {
          ...state.commonServices.skillsByCategory[action.payload.categoryId],
          [action.payload.data.id]: action.payload.data,
        };
        state.commonServices.isLoading = false;
        break;
      case types.ADD_CATEGORY:
        state.commonServices.categoryIds = [
          ...state.commonServices.categoryIds,
          action.payload.data.id,
        ];
        state.commonServices.categories = {
          ...state.commonServices.categories,
          [action.payload.data.id]: action.payload.data,
        };
        break;
      // Languages
      case types.GET_LANGUAGES_SUCCESS:
        state.commonServices.language = action.payload.data;
        break;
      // Gender
      case types.GET_GENDER_SUCCESS:
        state.commonServices.gender = action.payload.data;
        break;
      // Marital Status
      case types.GET_MARITALSTATUS_SUCCESS:
        state.commonServices.maritalStatus = action.payload.data;
        break;
      // Currency
      case types.GET_CURRENCY_SUCCESS:
        state.commonServices.currency = action.payload.data;
        break;
      default:
        return;
    }
  });
export default ICommonReducer;
