export const GET_CANDIDATE_DETAIL_REQUEST = 'GET_CANDIDATE_DETAIL_REQUEST';
export const GET_CANDIDATE_DETAIL_SUCCESS = 'GET_CANDIDATE_DETAIL_SUCCESS';
export const GET_CANDIDATE_DETAIL_FAIL = 'GET_CANDIDATE_DETAIL_FAIL';

// Update Candidate Detail
export const UPDATE_CANDIDATE_DETAIL_REQUEST = 'UPDATE_CANDIDATE_DETAIL_REQUEST';
export const UPDATE_CANDIDATE_DETAIL_SUCCESS = 'UPDATE_CANDIDATE_DETAIL_SUCCESS';
export const UPDATE_CANDIDATE_DETAIL_FAIL = 'UPDATE_CANDIDATE_DETAIL_FAIL';

export const CREATE_CANDIDATE_INTERVIEW_REQUEST = 'CREATE_CANDIDATE_INTERVIEW_REQUEST';
export const CREATE_CANDIDATE_INTERVIEW_SUCCESS = 'CREATE_CANDIDATE_INTERVIEW_SUCCESS';
export const CREATE_CANDIDATE_INTERVIEW_FAIL = 'CREATE_CANDIDATE_INTERVIEW_FAIL';

// Hiring
export const UPDATE_CANDIDATE_HIRING_REQUEST = 'UPDATE_CANDIDATE_HIRING_REQUEST';
export const UPDATE_CANDIDATE_HIRING_SUCCESS = 'UPDATE_CANDIDATE_HIRING_SUCCESS';
export const UPDATE_CANDIDATE_HIRING_FAIL = 'UPDATE_CANDIDATE_HIRING_FAIL';

// Interview
export const UPDATE_CANDIDATE_INTERVIEW_REQUEST = 'UPDATE_CANDIDATE_INTERVIEW_REQUEST';
export const UPDATE_CANDIDATE_INTERVIEW_SUCCESS = 'UPDATE_CANDIDATE_INTERVIEW_SUCCESS';
export const UPDATE_CANDIDATE_INTERVIEW_FAIL = 'UPDATE_CANDIDATE_INTERVIEW_FAIL';

// Skills
export const GET_CANDIDATE_SKILL_REQUEST = 'GET_CANDIDATE_SKILL_REQUEST';
export const GET_CANDIDATE_SKILL_SUCCESS = 'GET_CANDIDATE_SKILL_SUCCESS';
export const GET_CANDIDATE_SKILL_FAIL = 'GET_CANDIDATE_SKILL_FAIL';

export const UPDATE_CANDIDATE_SKILL_REQUEST = 'UPDATE_CANDIDATE_SKILL_REQUEST';
export const UPDATE_CANDIDATE_SKILL_SUCCESS = 'UPDATE_CANDIDATE_SKILL_SUCCESS';
export const UPDATE_CANDIDATE_SKILL_FAIL = 'UPDATE_CANDIDATE_SKILL_FAIL';

// Interview
export const GET_INTERVIEW_METHOD_SUCCESS = 'GET_INTERVIEW_METHOD_SUCCESS';
export const GET_INTERVIEW_TYPE_SUCCESS = 'GET_INTERVIEW_TYPE_SUCCESS';

export const CREATE_INTERVIEW_SUCCESS = 'CREATE_INTERVIEW_SUCCESS';
export const CREATE_INTERVIEW_FAIL = 'CREATE_INTERVIEW_FAIL';

export const GET_INTERVIEW_LIST_SUCCESS = 'GET_INTERVIEW_LIST_SUCCESS';
export const GET_INTERVIEW_LIST_FAIL = 'GET_INTERVIEW_LIST_FAIL';

export const UPDATE_INTERVIEW_SUCCESS = 'UPDATE_INTERVIEW_SUCCESS';
export const UPDATE_INTERVIEW_FAIL = 'UPDATE_INTERVIEW_FAIL';
export const UPDATE_INTERVIEW_REQUEST = 'UPDATE_INTERVIEW_REQUEST';

// delete
export const DELETE_INTERVIEW_SUCCESS = 'DELETE_INTERVIEW_SUCCESS';
export const DELETE_INTERVIEW_FAIL = 'DELETE_INTERVIEW_FAIL';
export const DELETE_INTERVIEW_REQUEST = 'DELETE_INTERVIEW_REQUEST';
