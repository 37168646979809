export const GET_LIST_JOB_REQUEST = 'GET_LIST_JOB_REQUEST';
export const GET_LIST_JOB_SUCCESS = 'GET_LIST_JOB_SUCCESS';
export const GET_LIST_JOB_FAIL = 'GET_LIST_JOB_FAIL';

export const ADD_NEW_JOB_REQUEST = 'ADD_NEW_JOB_REQUEST';
export const ADD_NEW_JOB_SUCCESS = 'ADD_NEW_JOB_SUCCESS';
export const ADD_NEW_JOB_FAIL = 'ADD_NEW_JOB_FAIL';

export const DELETE_JOB_REQUEST = 'DELETE_JOB_REQUEST';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAIL = 'DELETE_JOB_FAIL';

// Vacancy
export const GET_VACANCY_REQUEST = 'GET_VACANCY_REQUEST';
export const GET_VACANCY_SUCCESS = 'GET_VACANCY_SUCCESS';
export const GET_VACANCY_FAIL = 'GET_VACANCY_FAIL';

export const ADD_VACANCY_REQUEST = 'ADD_VACANCY_REQUEST';
export const ADD_VACANCY_SUCCESS = 'ADD_VACANCY_SUCCESS';
export const ADD_VACANCY_FAIL = 'ADD_VACANCY_FAIL';

// Work Schedule
export const GET_WORKSCHEDULE_REQUEST = 'GET_WORKSCHEDULE_REQUEST';
export const GET_WORKSCHEDULE_SUCCESS = 'GET_WORKSCHEDULE_SUCCESS';
export const GET_WORKSCHEDULE_FAIL = 'GET_WORKSCHEDULE_FAIL';

// Status
export const UPDATE_JOB_STATUS_REQUEST = 'UPDATE_JOB_STATUS_REQUEST';
export const UPDATE_JOB_STATUS_SUCCESS = 'UPDATE_JOB_STATUS_SUCCESS';
export const UPDATE_JOB_STATUS_FAIL = 'UPDATE_JOB_STATUS_FAIL';

export const GET_JOB_STATUS_REQUEST = 'GET_JOB_STATUS_REQUEST';
export const GET_JOB_STATUS_SUCCESS = 'GET_JOB_STATUS_SUCCESS';
export const GET_JOB_STATUS_FAIL = 'GET_JOB_STATUS_FAIL';

export const DELETE_VACANCY_SUCCESS = 'DELETE_VACANCY_SUCCESS';
export const UPDATE_VACANCY_SUCCESS = 'UPDATE_VACANCY_SUCCESS';
