import * as types from './constants';
export const getSkill = (data: object) => ({
  type: types.GET_SKILL_LIST_SUCCESS,
  payload: { data },
});

export const getAllSkillFromCategory = (data: object) => ({
  type: types.GET_ALL_SKILLS_FROM_CATEGORY_SUCCESS,
  payload: { data },
});

export const getAllSkillFromCategoryRequest = () => ({
  type: types.GET_ALL_SKILLS_FROM_CATEGORY_REQUEST,
});

export const getAllCategories = (data: object) => ({
  type: types.GET_SKILL_CATEGORIES_SUCCESS,
  payload: { data },
});

export const getAllSkillFromCategoryFail = () => ({
  type: types.GET_ALL_SKILLS_FROM_CATEGORY_FAIL,
});
export const addSkill = (data: object, categoryId: number) => ({
  type: types.ADD_SKILL,
  payload: { data, categoryId },
});

export const addCategory = (data: { id: number; name: string }) => ({
  type: types.ADD_CATEGORY,
  payload: { data },
});

// Gender
export const getGenderSuccess = (data: object) => ({
  type: types.GET_GENDER_SUCCESS,
  payload: { data },
});

// Marital Status
export const getMaritalStausSuccess = (data: object) => ({
  type: types.GET_MARITALSTATUS_SUCCESS,
  payload: { data },
});

// Languages
export const getLanguagesSuccess = (data: object) => ({
  type: types.GET_LANGUAGES_SUCCESS,
  payload: { data },
});

// Currency
export const getCurrencySuccess = (data: object) => ({
  type: types.GET_CURRENCY_SUCCESS,
  payload: { data },
});
