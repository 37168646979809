import { produce } from 'immer';
import * as types from './constants';
import { IProfile } from '.';
import { AuthState } from 'src/@types/auth';

const profileReducer = (action: { type: string; payload?: any }) => (state: AuthState) =>
  produce(state as AuthState, (draft: IProfile) => {
    switch (action.type) {
      case types.GET_PROFILE_REQUEST:
        draft.profile.isLoading = true;
        break;
      case types.GET_PROFILE_SUCCESS:
        draft.profile.isLoading = false;
        draft.profile.userInfor = action.payload.data;
        state.auth.user.personInfo = action.payload.data;
        break;
      case types.UPDATE_PROFILE_REQUEST:
        draft.profile.isLoading = true;
        break;
      case types.UPDATE_PROFILE_SUCCESS:
        draft.profile.isLoading = true;
        draft.profile.userInfor = { ...draft.profile.userInfor, ...action.payload.data };
        state.auth.user.personInfo = action.payload.data;
        break;
      default:
        break;
    }
  });
export default profileReducer;
