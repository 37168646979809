import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import GuestGuard from 'src/guards/GuestGuard';

interface IProps {
  children: React.ReactNode;
}

const Layout = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  backgroundImage:
    'url(https://images.wallpaperscraft.com/image/single/triangle_bright_colorful_background_63450_2560x1440.jpg)',
  [theme.breakpoints.down(1200)]: {
    justifyContent: 'center',
    overflowY: 'auto',
  },
}));

const Background = styled('div')(({ theme }) => ({
  width: '65%',
  [theme.breakpoints.down(1200)]: {
    display: 'none',
  },
}));
const Leftside = styled('div')(({ theme }) => ({
  marginLeft: '4%',
}));
const Rightside = styled('div')(({ theme }) => ({
  width: '35%',
  backgroundColor: 'white',
  margin: 24,
  [theme.breakpoints.down(1200)]: {
    display: 'flex',
    margin: 'auto',
    width: '80%',
  },
}));

const AuthLayout = (props: IProps) => {
  const { children } = props;
  return (
    <GuestGuard>
      <Layout>
        <Background>
          <Leftside>
            <Stack direction="column" sx={{ mb: 5, mt: 40, color: '#fff' }}>
              <Typography variant="h3" sx={{ px: 5 }}>
                FAJNA OS
              </Typography>
              <Typography sx={{ px: 5 }}>
                Fajna is the modern all-in-one HR solution.
                <br />
                Spend less time on processes and more time people.
              </Typography>
            </Stack>
          </Leftside>
        </Background>
        <Rightside>
          <Box sx={{ height: '100%', pl: 6, pr: 8, overflowY: 'auto' }}>{children}</Box>
        </Rightside>
      </Layout>
    </GuestGuard>
  );
};

export default AuthLayout;
