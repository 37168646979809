import * as types from './constants';

export const getUserRequest = () => ({
  type: types.GET_USER_REQUEST,
});

export const getUserSucces = (data: object[]) => ({
  type: types.GET_USER_SUCCESS,
  payload: { data },
});
