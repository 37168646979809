// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, Chip, TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  valueoption: {
    name: string;
    id: number;
    alpha3: string;
    description: string;
  }[];
};

type Props = IProps & TextFieldProps;

export default function RHFMultipleSelect({ name, valueoption = [] }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          multiple
          freeSolo
          onChange={(event, newValue) => field.onChange(newValue)}
          options={valueoption || []}
          getOptionLabel={(option) => option.name}
          renderTags={(value, getTagProps) =>
            value.map((option: { name: string }, index: number) => (
              <Chip {...getTagProps({ index })} key={index} size="small" label={option.name} />
            ))
          }
          renderInput={(params) => <TextField {...params} />}
        />
      )}
    />
  );
}
