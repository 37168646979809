import * as Yup from 'yup';

export const NewCompanySchema = Yup.object().shape({
  fullName: Yup.string().required('Name of the company is required'),
  address: Yup.string().required('Address is required'),
  vatin: Yup.string().required('VAT Identification is required'),
  email: Yup.string().required('Email is required').email(),
  city: Yup.string().required('City is required'),
  countryAlpha3Code: Yup.string().required('Country Code is required'),
});
