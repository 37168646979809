import {
  Inbox as InboxIcon,
  Dashboard as DashboardIcon,
  CalendarToday as CalendarTodayIcon,
  Work as WorkIcon,
  People as PeopleIcon,
  CreditCard as CreditCardIcon,
  PersonSearch as PersonSearchIcon,
} from '@mui/icons-material';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: <DashboardIcon />,
  mail: <InboxIcon />,
  calendar: <CalendarTodayIcon />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: '/dashboard/app', icon: ICONS.dashboard },
      { title: 'Calendar', path: '/dashboard/calendar', icon: ICONS.calendar },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'jobs',
        path: '/dashboard/jobs',
        icon: <WorkIcon />,
      },
      {
        title: 'vacancy',
        path: '/dashboard/vacancy',
        icon: <PersonSearchIcon />,
      },
      {
        title: 'candidates',
        path: '/dashboard/candidates',
        icon: ICONS.user,
      },
      {
        title: 'employee',
        path: '/dashboard/employee',
        icon: <PeopleIcon />,
      },
      {
        title: 'department',
        path: '/dashboard/department/list',
        icon: <PeopleIcon />,
      },
    ],
  },
];

export default navConfig;
