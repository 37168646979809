import produce from 'immer';
import { ActionTypes } from 'src/interfaces';
import { IDepartment } from '.';
import * as types from './constants';

const departmentReducer = (action: ActionTypes) =>
  produce((state: IDepartment) => {
    switch (action.type) {
      case types.GET_LIST_DEPARTMENT_REQUEST:
        state.department.isLoading = true;
        break;
      case types.GET_LIST_DEPARTMENT_SUCCESS:
        state.department.departments = action.payload.data.departments;
        state.department.departmentIds = action.payload.data.departmentIds;
        state.department.isLoading = false;
        break;
      case types.GET_LIST_DEPARTMENT_FAIL:
        state.department.isLoading = false;
        state.department.departments = {};
        state.department.departmentIds = [];
        break;
      case types.CREATE_DEPARTMENT_SUCCESS:
        state.department.departments = action.payload.data.departments;
        state.department.departmentIds = action.payload.data.departmentIds;
        break;

      case types.DELETE_DEPARTMENT_SUCCESS:
        state.department.departments = action.payload.data.departments;
        state.department.departmentIds = action.payload.data.departmentIds;
        break;
      case types.UPDATE_DEPARTMENT_SUCCESS:
        state.department.departments = action.payload.data.departments;
        state.department.departmentIds = action.payload.data.departmentIds;
        break;
      default:
        return;
    }
  });
export default departmentReducer;
