import produce from 'immer';
import { ICompany } from '.';
import { ActionTypes } from 'src/interfaces';
import * as types from './constants';

import { AuthState } from 'src/@types/auth';

const companyReducer = (action: ActionTypes) => (state: AuthState) =>
  produce(state as AuthState, (draft: ICompany) => {
    switch (action.type) {
      case types.CREATE_NEW_COMPANY_REQUEST:
        draft.company.isLoading = true;
        break;

      case types.CREATE_NEW_COMPANY_SUCCESS:
        draft.company.isLoading = false;
        state.auth.user.companyInfo = action.payload.data;
        break;

      case types.CREATE_NEW_COMPANY_FAIL:
        draft.company.isLoading = false;
        break;

      case types.UPDATE_COMPANY_REQUEST:
        draft.company.isLoading = true;
        break;

      case types.UPDATE_COMPANY_SUCCESS:
        state.auth.user.companyInfo = action.payload.data;
        draft.company.isLoading = false;
        break;

      case types.UPDATE_COMPANY_FAIL:
        draft.company.isLoading = false;
        break;

      default:
        return;
    }
  });

export default companyReducer;
