import axios from 'src/utils/axios';
import * as ActionServices from './action';
// Gender
export const getGender = async (dispatch: {
  (action: { type: string; payload?: object }): void;
}) => {
  try {
    const res = await axios.get(`/api/v1/Person/Directory/Gender`);
    const data = res.data.reduce(
      (
        gender: {
          genderIds: number[];
          gender: object;
        },
        item: { id: number }
      ) => {
        gender.genderIds.push(item.id);
        gender.gender = { ...gender.gender, [item.id]: item };
        return gender;
      },
      {
        genderIds: [],
        gender: {},
      }
    );
    dispatch(ActionServices.getGenderSuccess(data));
  } catch (error) {
    console.log(error);
  }
};

// Marital Status
export const getMaritalStatus = async (dispatch: {
  (action: { type: string; payload?: object }): void;
}) => {
  try {
    const res = await axios.get(`/api/v1/Person/Directory/MaritalStatus`);
    const data = res.data.reduce(
      (
        maritalstatus: {
          maritalStatusIds: number[];
          maritalStatus: object;
        },
        item: { id: number }
      ) => {
        maritalstatus.maritalStatusIds.push(item.id);
        maritalstatus.maritalStatus = { ...maritalstatus.maritalStatus, [item.id]: item };
        return maritalstatus;
      },
      {
        maritalStatusIds: [],
        maritalStatus: {},
      }
    );
    dispatch(ActionServices.getMaritalStausSuccess(data));
  } catch (error) {
    console.log(error);
  }
};
