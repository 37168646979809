export const GET_SKILL_LIST_REQUEST = 'GET_SKILL_LIST';
export const GET_SKILL_LIST_SUCCESS = 'GET_SKILL_LIST_SUCCESS';

export const GET_ALL_SKILLS_FROM_CATEGORY_REQUEST = 'GET_ALL_SKILLS_FROM_CATEGORY_REQUEST';
export const GET_ALL_SKILLS_FROM_CATEGORY_SUCCESS = 'GET_ALL_SKILLS_FROM_CATEGORY_SUCCESS';
export const GET_ALL_SKILLS_FROM_CATEGORY_FAIL = 'GET_ALL_SKILLS_FROM_CATEGORY_FAIL';

export const GET_SKILL_CATEGORIES_SUCCESS = 'GET_SKILL_CATEGORIES_SUCCESS';

export const ADD_SKILL_REQUEST = 'ADD_SKILL_REQUEST';
export const ADD_SKILL = 'ADD_SKILL';

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY = 'ADD_CATEGORY';

// Gender
export const GET_GENDER_SUCCESS = 'GET_GENDER_SUCCESS';

//Marital Status
export const GET_MARITALSTATUS_SUCCESS = 'GET_MARITALSTATUS_SUCCESS';

// Languages
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';

// Currency
export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS';
