import AuthLayout from 'src/layouts/AuthLayout';
import LoginContainer from './views';

function Login() {
  return (
    <AuthLayout>
      <LoginContainer />
    </AuthLayout>
  );
}
export default Login;
