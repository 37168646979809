import { IDirectory, jobType, VancancyType } from 'src/@types/jobs';
import { IPassport } from 'src/@types/user';

export interface IVacancySkill {
  keySkills: [
    {
      id: number;
      category: {
        id: number;
        name: string;
      };
      title: string;
      description: string;
    }
  ];
  additionalSkills: [
    {
      id: number;
      category: {
        id: number;
        name: string;
      };
      title: string;
      description: string;
    }
  ];
}

export interface IVacancySkills {
  category: string;
  title: string;
  id: number;
}

export type JobDetail = {
  isLoading: boolean;
  job: jobType;
  column: {
    cols: {
      [key: string]: {
        candidateIds: string[];
        id: number;
        name: string;
      };
    };
    colIds: [];
    candidates: {
      [id: number | string]: {
        shiftDate: string;
        additionalInfo: string;
        applyDate: string;
        candidateId: number;
        rating: number;
        resourceId: number;
        stageId: number;
      };
    };
  };
  candidates: { [keys: number]: IPassport };
  candidateIds: number[];
  vacancy: VancancyType;
  hiring: IDirectory[];
  recourse: {
    [id: number]: IDirectory;
  };
  vacancySkill: IVacancySkills[];
};

export interface IJobDetailReducer {
  jobDetail: JobDetail;
  dispatchJobDetail: (action: { type: string; payload?: any }) => void;
}
export const initialState = {
  isLoading: false,
  job: {},
  column: {
    candidates: {},
  },
  candidates: {},
  candidateIds: [],
  vacancy: {},
  hiring: [],
  recourse: {},
  vacancySkill: [],
};
