import { IGender, IMaterialStatus } from 'src/@types/user';
import { ActionTypes } from 'src/interfaces';
export interface ICateggory {
  id: number;
  name: string;
}

export interface ISkill {
  id: number;
  category: {
    id: number;
    name: string;
  };
  title: string;
  description: string;
}
export interface IEducation {
  id?: number;
  institution: {
    id?: number;
    name: string;
    address: {
      countryAlpha3Code: string;
      city: string;
    };
    siteSrt?: string;
  };
  level: {
    id: number | string;
    name: string;
  };
  endingYear: number | string | Date;
  programTitle: string;
  onLine: boolean;
}

export interface ILanguage {
  name: string;
  id: number;
  alpha3: string;
  description: string;
}
export interface ICurrency {
  alpha3: string;
  num: number;
  title: string;
}
export interface ICommon {
  commonServices: {
    skillsByCategory: ISkill[];
    categoryIds: number[];
    categories: {
      id: number;
      name: string;
    }[];
    language: {
      languagesIds: number[];
      languages: {
        alpha2: string;
        alpha3: string;
        description: string;
        id: number;
        name: string;
      }[];
    };
    gender: {
      genderIds: number[];
      gender: IGender[];
    };
    maritalStatus: {
      maritalStatusIds: number[];
      maritalStatus: IMaterialStatus[];
    };
    currency: ICurrency[];
    personSkill: ISkill[];
    personLanguage: ILanguage;
    isLoading: boolean;
  };
  dispatchCommon: (action: ActionTypes) => void;
}

export const initalState = {
  isLoading: true,
  skillsByCategory: [],
  categoryIds: [],
  categories: {},
  language: {
    languagesIds: [],
    languages: {},
  },
  maritalStatus: {
    maritalStatusIds: [],
    maritalStatus: {},
  },
  gender: {
    genderIds: [],
    gender: {},
  },
  currency: [],
  personLanguage: {},
};
