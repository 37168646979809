import { IDirectory, VancancyType, jobType } from 'src/@types/jobs';
export interface IWorkSchedule {
  id: number;
  name: string;
}
export interface IPublications {
  id: number;
  resourceId: number;
  publicationDate: string;
}
export interface IJobReducer {
  job: {
    isLoading: boolean;
    jobIds: string[];
    jobs: {
      [key: string]: jobType;
    };
    vacancyIds: number[];
    vacancies: VancancyType[];
    workSchedule: IWorkSchedule[];
    status: {
      [id: number | string]: IDirectory;
    };
  };
  dispatchJob: (action: { type: string; payload?: any }) => void;
}

export const initialState = {
  isLoading: false,
  jobIds: [],
  jobs: {},
  vacancyIds: [],
  vacancies: {},
  candidate: [],
  workSchedule: [],
  status: {},
};
