import { VariantType } from 'notistack';
import { ActionTypes } from 'src/interfaces';
import axios from 'src/utils/axios';
import { updateCompanyFail, updateCompanySuccess } from './action';

export const createCompany = async (
  dispatch: {
    (action: ActionTypes): void;
  },
  data: object,
  callback: (message: string, status: VariantType) => void,
  onClose: () => void
) => {
  try {
    const res = await axios.post('/api/v1/company', data);
    dispatch({
      type: 'CREATE_COMPANY',
      payload: { data: res.data.baseInfo },
    });
    onClose();
    callback('Create a successful company', 'success');
  } catch (error) {
    callback('Failed to create company', 'error');
  }
};

export const updateCompany = async (
  dispatch: {
    (action: ActionTypes): void;
  },
  data: object,
  handleSnacker: (message: string, variant: VariantType) => void
) => {
  try {
    await dispatch(updateCompanySuccess(data));
    handleSnacker('Update company successed', 'success');
    return;
  } catch (error) {
    dispatch(updateCompanyFail(error.message));
    handleSnacker('Update company faild', 'error');
  }
  return;
};

export const getCompanyById = async (companyId: number) => {
  try {
    await axios.get(`/api/v1/Company/${companyId}`);
  } catch (error) {}
};

export const deleteCompanyById = async (companyId: number) => {
  try {
    await axios.delete(`/api/v1/Company/${companyId}`);
  } catch (error) {}
};

export const getCompanyByName = async (companyName: string) => {
  try {
    await axios.get(`/api/v1/Company/GetByName/${companyName}`);
  } catch (error) {}
};

export const createCompanyManager = async (companyId: number, data: object) => {
  try {
    axios.post(`/api/v1/Company/${companyId}/_InPROGRESS_Manager`, data);
  } catch (error) {}
};

export const getCompanyManager = async (companyId: number) => {
  try {
    await axios.get(`/api/v1/Company/${companyId}/_InPROGRESS_Manager`);
  } catch (error) {}
};

export const deleteCompanyManager = async (companyId: number) => {
  try {
    await axios.delte(`/api/v1/Company/${companyId}/_InPROGRESS_Manager`);
  } catch (error) {}
};

export const createCompanySocialAcc = async (companyId: number, data: object) => {
  try {
    await axios.post(`/api/v1/Company/${companyId}/SocialAccount`, data);
  } catch (error) {}
};

export const getCompanySocialAcc = async (companyId: number) => {
  try {
    await axios.get(`/api/v1/Company/${companyId}/SocialAccount`);
  } catch (error) {}
};

export const deleteCompanySocialAcc = async (companyId: number) => {
  try {
    await axios.delete(`/api/v1/Company/${companyId}/SocialAccount`);
  } catch (error) {}
};

// update company
export const updateCompanyFullName = async (
  dispatch: { (action: ActionTypes): void },
  companyId: number | string,
  fullName: string
) => {
  try {
    const res = await axios.put(`/api/v1/Company/${companyId}/FullName/${fullName}`);
    dispatch({ type: 'UPDATE_COMPANY', payload: { data: res.data } });
  } catch (error) {}
};

export const updateCompanyPhoneNumber = async (
  dispatch: { (action: ActionTypes): void },
  companyId: number | string,
  phoneNumber: string | number
) => {
  try {
    const res = await axios.put(`/api/v1/Company/${companyId}/Phone/${phoneNumber}`);
    dispatch({ type: 'UPDATE_COMPANY', payload: { data: res.data } });
  } catch (error) {}
};

export const updateCompanyEmail = async (
  dispatch: { (action: ActionTypes): void },
  companyId: number | string,
  email: string
) => {
  try {
    const res = await axios.put(`/api/v1/Company/${companyId}/Email/${email}`);
    dispatch({ type: 'UPDATE_COMPANY', payload: { data: res.data } });
  } catch (error) {}
};

export const updateCompanyVatin = async (
  dispatch: { (action: ActionTypes): void },
  companyId: number | string,
  vatin: string
) => {
  try {
    const res = await axios.put(`/api/v1/Company/${companyId}/Vatin/${vatin}`);
    dispatch({ type: 'UPDATE_COMPANY', payload: { data: res.data } });
  } catch (error) {}
};

export const updateCompanyLegalAddress = async (
  dispatch: { (action: ActionTypes): void },
  companyId: number | string,
  legalAddress: object
) => {
  try {
    const res = await axios.put(`/api/v1/Company/${companyId}/LegalAddress`, legalAddress);
    dispatch({ type: 'UPDATE_COMPANY', payload: { data: res.data } });
  } catch (error) {}
};
