import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, MenuItem, Stack, Typography } from '@mui/material';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { VariantType, useSnackbar } from 'notistack';

import { FormProvider, RHFDatePicker, RHFTextField } from 'src/components/hook-form';
import RHFPhoneInput from 'src/components/hook-form/RHFPhoneInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useAuth from 'src/hooks/useAuth';
import { useEffect, useMemo } from 'react';
import { ICommon } from 'src/commonServices';
import { useStore } from 'src/store';
import { getGender } from 'src/commonServices/person';

interface FormValuesProps extends Omit<any, 'avatarUrl'> {
  avatarUrl: string | null;
}

export const NewSchemaProInfo = Yup.object().shape({
  name: Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is reuired'),
  }),
  email: Yup.string().required('Email is Required'),
});

interface IStore extends ICommon {}
const UserInforForm = () => {
  const { dispatchCommon, commonServices } = useStore((store) => store as IStore);
  const { gender, genderIds } = commonServices.gender;
  const { createUserInfor } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getGender(dispatchCommon);
  }, [dispatchCommon]);

  const defaultValues = useMemo(
    () => ({
      name: {
        firstName: '',
        middleName: '',
        lastName: '',
      },
      genderId: genderIds[0],
      date: new Date('01/01/1990'),
      email: '',
      phone: '',
    }),
    [genderIds]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewSchemaProInfo),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const showSnackBar = (message: string, status: VariantType) => {
    enqueueSnackbar(message, {
      variant: status,
    });
  };

  const onSubmit = async () => {
    const isValidphone = parsePhoneNumberFromString(`+${getValues().phone}`)?.isValid();
    if (isValidphone) {
      const { date, ...rest } = getValues();

      const payload = {
        person: {
          birthday: {
            year: new Date(date).getFullYear(),
            month: new Date(date).getMonth() + 1,
            day: new Date(date).getDate(),
          },
          ...rest,
        },
      };
      await createUserInfor(payload, showSnackBar);
      // enqueueSnackbar('Create new User Information Successed!');
      return reset();
    } else {
      return enqueueSnackbar('Your phone number is invalid', { variant: 'error' });
    }
  };

  return (
    <Box sx={{ pr: 20, pl: 20 }}>
      <Box
        sx={{
          boxShadow: '0px 0px 2px 0px black',
          borderRadius: 1,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              textAlign: 'center',
              pt: 2,
            }}
          >
            <Typography variant="h3">User Information</Typography>
          </Box>

          <Stack sx={{ p: 2, width: '100%' }} spacing={3}>
            <Box
              sx={{
                width: '100%',
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField fullWidth name="name.firstName" label="First Name" />
              <RHFTextField fullWidth name="name.lastName" label="Last Name" />
              <RHFTextField fullWidth name="name.middleName" label="Middle Name" />
              <RHFTextField fullWidth name="email" label="Email" />
              <RHFPhoneInput fullWidth name="phone" label="Phone" />
              <RHFTextField select fullWidth label="Gender" name="genderId">
                {genderIds.map((id) => (
                  <MenuItem value={id} key={id}>
                    {gender[id]?.description}
                  </MenuItem>
                ))}
              </RHFTextField>
              <RHFDatePicker name="date" label="Birthdate" maxDate={new Date()} />
            </Box>

            <Stack alignItems="center" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                // fullWidth
                loading={isSubmitting}
                sx={{
                  minWidth: '400px',
                }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Stack>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default UserInforForm;
