import produce from 'immer';
import { ActionTypes } from 'src/interfaces';
import { ICandidate } from '.';
import * as types from './constants';

const candidateDetailReducer = (action: ActionTypes) =>
  produce((state: ICandidate) => {
    switch (action.type) {
      case types.GET_CANDIDATE_DETAIL_REQUEST:
        state.candidateDetail.isLoading = true;
        break;
      case types.GET_CANDIDATE_DETAIL_SUCCESS:
        state.candidateDetail.isLoading = false;
        state.candidateDetail.candidate = action.payload.data;
        break;
      case types.GET_CANDIDATE_DETAIL_FAIL:
        state.candidateDetail.isLoading = false;
        break;
      // Update Candidate
      case types.UPDATE_CANDIDATE_DETAIL_REQUEST:
        state.candidateDetail.isLoading = true;
        break;
      case types.UPDATE_CANDIDATE_DETAIL_SUCCESS:
        state.candidateDetail.isLoading = false;
        state.candidateDetail.candidate.passport = action.payload.data;
        break;
      case types.UPDATE_CANDIDATE_DETAIL_FAIL:
        state.candidateDetail.isLoading = false;
        break;

      //Hiring
      case types.UPDATE_CANDIDATE_HIRING_REQUEST:
        state.candidateDetail.isLoading = true;
        break;
      case types.UPDATE_CANDIDATE_HIRING_SUCCESS:
        state.candidateDetail.hiring.notes = action.payload.data.notes;
        state.candidateDetail.isLoading = false;
        break;
      case types.UPDATE_CANDIDATE_HIRING_FAIL:
        state.candidateDetail.isLoading = false;
        break;

      // Interview create
      case types.CREATE_CANDIDATE_INTERVIEW_REQUEST:
        state.candidateDetail.isLoading = true;
        break;
      case types.CREATE_CANDIDATE_INTERVIEW_SUCCESS:
        state.candidateDetail.isLoading = false;
        state.candidateDetail.candidate.userType.interviewList = [
          ...state.candidateDetail.candidate.userType.interviewList,
          action.payload.data,
        ];
        break;
      case types.CREATE_CANDIDATE_INTERVIEW_FAIL:
        state.candidateDetail.isLoading = false;
        break;
      // Interview update
      case types.UPDATE_CANDIDATE_INTERVIEW_REQUEST:
        state.candidateDetail.isLoading = true;
        break;
      case types.UPDATE_CANDIDATE_INTERVIEW_SUCCESS:
        state.candidateDetail.candidate.userType.interviewList = [
          ...state.candidateDetail.candidate.userType.interviewList,
          action.payload.data,
        ];
        state.candidateDetail.isLoading = false;
        break;
      case types.UPDATE_CANDIDATE_INTERVIEW_FAIL:
        state.candidateDetail.isLoading = false;
        break;
      // Skills
      case types.GET_CANDIDATE_SKILL_REQUEST:
        state.candidateDetail.isLoading = true;
        break;
      case types.GET_CANDIDATE_SKILL_SUCCESS:
        state.candidateDetail.isLoading = false;
        state.candidateDetail.candidate.skills = action.payload.data;
        break;
      case types.GET_CANDIDATE_SKILL_FAIL:
        state.candidateDetail.isLoading = false;
        break;

      case types.UPDATE_CANDIDATE_SKILL_REQUEST:
        state.candidateDetail.isLoading = true;
        break;
      case types.UPDATE_CANDIDATE_SKILL_SUCCESS:
        state.candidateDetail.isLoading = false;
        state.candidateDetail.candidate.skills = action.payload.data;
        break;
      case types.UPDATE_CANDIDATE_SKILL_FAIL:
        state.candidateDetail.isLoading = false;
        break;

      // Interview
      case types.GET_INTERVIEW_METHOD_SUCCESS:
        state.candidateDetail.interviewMethod = action.payload.data;
        break;
      case types.GET_INTERVIEW_TYPE_SUCCESS:
        state.candidateDetail.interviewType = action.payload.data;
        break;

      case types.CREATE_INTERVIEW_SUCCESS:
        state.candidateDetail.interview = {
          ...state.candidateDetail.interview,
          [action.payload.data.id]: action.payload.data,
        };
        state.candidateDetail.interviewIds = [
          ...state.candidateDetail.interviewIds,
          action.payload.data.id,
        ];
        break;
      // update
      case types.UPDATE_INTERVIEW_SUCCESS:
        state.candidateDetail.interview = {
          ...state.candidateDetail.interview,
          [action.payload.data.id]: action.payload.data,
        };
        break;
      // delete
      case types.DELETE_INTERVIEW_SUCCESS:
        delete state.candidateDetail.interview[action.payload.data.id];
        const deleteData = state.candidateDetail.interviewIds.filter(
          (id) => id !== action.payload.data.id
        );
        break;
      case types.GET_INTERVIEW_LIST_SUCCESS:
        state.candidateDetail.interview = action.payload.data.interview;
        state.candidateDetail.interviewIds = action.payload.data.interviewIds;
        break;

      default:
        return;
    }
  });
export default candidateDetailReducer;
