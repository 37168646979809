import { IDirectory, jobType, VancancyType } from 'src/@types/jobs';
import { IPassport } from 'src/@types/user';

export type JobDetail = {
  isLoading: boolean;
  candidateIds: string[];
  candidates: { [keys: number]: IPassport };
};

export interface ICandidateReducer {
  candidate: JobDetail;
  dispatchCandidates: (action: { type: string; payload?: any }) => void;
}
export const initialState = {
  isLoading: false,
  candidates: {},
  candidateIds: [],
};
