import iso from 'iso-3166-1';
import { useSnackbar, VariantType } from 'notistack';
// hook-form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
// mui
import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Stack } from '@mui/material';
// store
import { useStore } from 'src/store';

import { NewCompanySchema } from '../schemas';
import { CustomFile } from 'src/components/upload/type';
import * as APIServices from '../store/ultis';
import RHFPhoneInput from 'src/components/hook-form/RHFPhoneInput';
import parsePhoneNumberFromString from 'libphonenumber-js';

import { AuthState } from 'src/@types/auth';
// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<any, 'avatarUrl'> {
  avatarUrl: CustomFile | string | null;
}

interface IProps {
  onClose: () => void;
}

export default function CreateCompany(props: IProps) {
  const { onClose } = props;
  const { dispatchJWT } = useStore<AuthState>((store) => store as AuthState);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    fullName: '',
    address: '',
    vatin: '',
    phone: '',
    email: '',
    countryAlpha3Code: '',
    city: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewCompanySchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const handlSnackbar = (message: string, status: VariantType) => {
    enqueueSnackbar(message, {
      variant: status,
    });
  };

  const onSubmit = async () => {
    const isValidphone = parsePhoneNumberFromString(`+${getValues().phone}`)?.isValid();
    if (isValidphone) {
      try {
        const { vatin, ...rest } = getValues();
        const payload = {
          vatin: `${getValues().countryAlpha3Code}${vatin}`,
          ...rest,
        };
        await APIServices.createCompany(dispatchJWT, payload, handlSnackbar, onClose);
        return reset();
      } catch (error) {
        console.error(error);
      }
    }
    return enqueueSnackbar('Your phone number is invalid', { variant: 'error' });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3, width: '100%' }}>
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            columnGap: 2,
            rowGap: 3,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >
          <RHFTextField fullWidth name="fullName" label="Name of Company" />
          <RHFTextField fullWidth name="email" label="Email" />
          <RHFPhoneInput fullWidth name="phone" label="Phone" height='57.5px' />
          <RHFTextField fullWidth name="address" label="Address" />
          <RHFTextField fullWidth name="city" label="City" />
          <RHFTextField name="countryAlpha3Code" select label="Country Alpha 3 Code">
            {iso.all().map((country, index) => (
              <MenuItem key={index} value={country.alpha3}>
                {country.country} ({country.alpha3})
              </MenuItem>
            ))}
          </RHFTextField>
          <RHFTextField fullWidth name="vatin" label="VAT Identification" />
        </Box>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
