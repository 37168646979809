import { ActionTypes } from 'src/interfaces';

export interface ICompany {
  company: {
    company: {
      id: string;
      name: string;
      email: string;
      type: string;
      address: string;
      vat: number;
      phone: string;
    };
    isLoading: boolean;
  };
  dispatchCompany: (action: ActionTypes) => void;
}

export const initialState = {
  company: {},
  isLoading: false,
};
