import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from 'src/guards/AuthGuard';
import Layout from 'src/layouts';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: '/user-info',
      element: <Navigate to="/user-info/create" replace />,
      children: [
        {
          path: 'create',
          element: <UserInforPage />,
        },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <Layout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace />, index: true },
        { path: 'app', element: <DashboardPage /> },
        { path: 'candidates', element: <PageTwo /> },
        {
          path: 'jobs',
          children: [
            { element: <Navigate to="/dashboard/jobs/list" replace />, index: true },
            { path: 'list', element: <JobPage /> },
            { path: ':id', element: <PageJobDetail /> },
            { path: ':id/:id', element: <CadidateDetail /> },
          ],
        },
        {
          path: 'employee',
          children: [
            { element: <Navigate to="/dashboard/employee/list" replace />, index: true },
            { path: 'list', element: <EmployeePage /> },
            { path: ':id', element: <EmployeeDetailPage /> },
          ],
        },
        { path: 'department/list', element: <Department /> },
        { path: 'calendar', element: <PageThree /> },
        { path: 'company', element: <CompanyPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'vacancy', element: <VacancyPage /> },
        { path: 'settings', element: <SettingsPage /> },
        // {
        //   path: 'user-infor',
        //   element: <UserInforPage />,
        // },
      ],
    },
    {
      path: 'auth',
      children: [
        { element: <Navigate to="/auth/login" replace />, index: true },
        {
          path: 'login',
          element: <LoginPage />,
        },
        {
          path: 'register',
          element: <RegisterPage />,
        },
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },

        {
          path: 'callback',
          element: <div>Callback</div>,
        },
      ],
    },
    {
      path: 'Account',
      children: [
        {
          path: 'Password/Reset',
          element: <NewPassword />,
        },
        {
          path: 'Register/ConfirmEmail',
          element: <ConfirmEmailPage />,
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const DashboardPage = Loadable(lazy(() => import('../pages/dashboard/app')));
const PageThree = Loadable(lazy(() => import('../pages/dashboard/calendar')));
const PageTwo = Loadable(lazy(() => import('../pages/dashboard/candidates')));
const CompanyPage = Loadable(lazy(() => import('../pages/dashboard/company')));
const Department = Loadable(lazy(() => import('../pages/dashboard/department')));
const JobPage = Loadable(lazy(() => import('../pages/dashboard/jobs')));
const EmployeePage = Loadable(lazy(() => import('../pages/dashboard/employee')));
const EmployeeDetailPage = Loadable(lazy(() => import('../pages/dashboard/employee/id')));
const PageJobDetail = Loadable(lazy(() => import('../pages/dashboard/jobs/[name]')));
const ProfilePage = Loadable(lazy(() => import('../pages/dashboard/profile')));
const CadidateDetail = Loadable(lazy(() => import('../pages/dashboard/jobs/[name]/[candidate]')));
const SettingsPage = Loadable(lazy(() => import('../pages/dashboard/settings')));
const UserInforPage = Loadable(lazy(() => import('../pages/dashboard/userInfor')));
const VacancyPage = Loadable(lazy(() => import('../pages/dashboard/vacancy')));

//auth
const LoginPage = Loadable(lazy(() => import('../pages/auth/login')));
const RegisterPage = Loadable(lazy(() => import('../pages/auth/register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/reset-password')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/new-password')));
const ConfirmEmailPage = Loadable(lazy(() => import('../pages/auth/confirm_email')));

//notfound
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
