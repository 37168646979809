import { IPassport } from '../../../@types/user';
import { ActionTypes } from 'src/interfaces';
export type EmployeeInfoGeneral = {
  companyId: number;
  department: number;
  id: number;
  statusId: number;
  isContract: boolean;
  personId: number;
  vacancyId?: number | string;
};
export type IEmployeeStatus = {
  id: number;
  name: string;
  comment: string;
};
export interface IEmployeeReducer {
  employee: {
    employeeIds: string[];
    employees: {
      [id: number | string]: IPassport;
    };
    employeeInfoById: {
      [key: number]: EmployeeInfoGeneral;
    };
    employeeStatus: {
      [key: number]: IEmployeeStatus;
    };
    isLoading: boolean;
  };
  dispatchEmployee: (action: ActionTypes) => void;
}

export const initialState = {
  employeeIds: [],
  employees: {},
  employeeInfoById: {},
  employeeStatus: {},
  isLoading: false,
};
