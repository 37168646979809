import { ActionTypes } from 'src/interfaces';

export interface IDashboardReducer {
  dashboard: {
    users: string[];
    isLoading: boolean;
  };
  dispatchDashboard: (action: ActionTypes) => void;
}

export const initialState = {
  users: [],
  isLoading: false,
};
