import produce from 'immer';
import { ActionTypes } from 'src/interfaces';
import { IDashboardReducer } from '.';
import * as types from './constants';
import { getListUsers } from './utils';

const dashboardReducer = (action: ActionTypes) =>
  produce((state: IDashboardReducer) => {
    switch (action.type) {
      case types.GET_USER_REQUEST:
        getListUsers(state.dispatchDashboard);
        state.dashboard.isLoading = true;
        break;
      case types.GET_USER_SUCCESS:
        state.dashboard.users = action.payload.data;
        state.dashboard.isLoading = false;
        break;
      default:
        return;
    }
  });
export default dashboardReducer;
