import produce from 'immer';
import { IJobDetailReducer } from '.';
import * as types from './constants';

const JobDetailReducer = (action: { type: string; payload?: any }) =>
  produce((state: IJobDetailReducer) => {
    switch (action.type) {
      case types.GET_JOB_DETAIL_REQUEST:
        state.jobDetail.isLoading = true;
        state.jobDetail.isLoading = true;
        break;
      case types.GET_JOB_DETAIL_SUCCESS:
        // state.jobDetail.job = {
        //   ...state.jobDetail.job,
        //   [action.payload.data.dataJob.id]: action.payload.data.dataJob,
        // };
        state.jobDetail.job = action.payload.data.dataJob;
        state.jobDetail.column = action.payload.data.formatCol;
        state.jobDetail.candidates = action.payload.data.candidate.candidates;
        state.jobDetail.candidateIds = action.payload.data.candidate.candidateIds;
        state.jobDetail.isLoading = false;
        break;
      case types.GET_JOB_DETAIL_FAIL:
        state.jobDetail.isLoading = false;
        break;
      case types.UPDATE_JOB_DETAIL_REQUEST:
        state.jobDetail.isLoading = true;
        break;
      case types.UPDATE_JOB_DETAIL_SUCCESS:
        state.jobDetail.isLoading = false;
        state.jobDetail.job = action.payload.data;
        break;
      case types.UPDATE_JOB_DETAIL_FAIL:
        state.jobDetail.isLoading = false;
        break;
      case types.UPDATE_STAGE_REQUEST:
        state.jobDetail.isLoading = true;
        break;
      case types.UPDATE_STAGE_SUCCESS:
        state.jobDetail.isLoading = false;
        state.jobDetail.column.cols = {
          ...state.jobDetail.column.cols,
          ...action.payload.data.cols,
        };
        state.jobDetail.column.candidates[action.payload.data.candidate.candidateId] =
          action.payload.data.candidate;
        break;

      case types.UPDATE_STAGE_FAIL:
        state.jobDetail.isLoading = false;
        state.jobDetail.column = action.payload.data;
        break;
      // Candidate
      case types.CREATE_CANDIDATE_REQUEST:
        state.jobDetail.isLoading = true;
        break;
      case types.CREATE_CANDIDATE_SUCCESS:
        state.jobDetail.candidateIds = [
          ...state.jobDetail.candidateIds,
          action.payload.data.newCandidate.id,
        ];
        state.jobDetail.candidates = {
          ...state.jobDetail.candidates,
          [action.payload.data.newCandidate.id]: action.payload.data.newCandidate,
        };
        state.jobDetail.column.cols[action.payload.data.colName].candidateIds = [
          ...state.jobDetail.column.cols[action.payload.data.colName].candidateIds,
          action.payload.data.newCandidate.id,
        ];
        state.jobDetail.column.candidates = {
          ...state.jobDetail.column.candidates,
          [action.payload.data.newCandidate.id]: action.payload.data.newCandidate.colId,
        };
        state.jobDetail.isLoading = false;
        break;
      case types.CREATE_CANDIDATE_FAIL:
        state.jobDetail.isLoading = false;
        break;

      // Delete
      case types.DELETE_CANDIDATE_REQUEST:
        state.jobDetail.isLoading = true;
        break;
      case types.DELETE_CANDIDATE_SUCCESS:
        state.jobDetail.isLoading = false;

        state.jobDetail.candidateIds = action.payload.data.candidateIds;
        delete state.jobDetail.candidates[action.payload.data.id];
        break;
      case types.DELETE_CANDIDATE_FAIL:
        state.jobDetail.isLoading = false;

        break;

      //Vacancy
      case types.GET_VACANCY_DETAIL_REQUEST:
        state.jobDetail.isLoading = true;
        break;
      case types.GET_VACANCY_DETAIL_SUCCESS:
        state.jobDetail.isLoading = false;
        state.jobDetail.vacancy = action.payload.data;
        break;
      case types.GET_VACANCY_DETAIL_FAIL:
        state.jobDetail.isLoading = false;
        break;

      case types.UPDATE_VACANCY_REQUEST:
        state.jobDetail.isLoading = true;
        break;
      case types.UPDATE_VACANCY_SUCCESS:
        state.jobDetail.isLoading = false;
        state.jobDetail.vacancy = action.payload.data;
        break;
      case types.UPDATE_VACANCY_FAIL:
        state.jobDetail.isLoading = false;
        break;

      // Vacancy skill
      case types.GET_VACANCY_SKILL_REQUEST:
        state.jobDetail.isLoading = true;
        break;
      case types.GET_VACANCY_SKILL_SUCCESS:
        state.jobDetail.isLoading = false;
        state.jobDetail.vacancySkill = action.payload.data;
        break;
      case types.GET_VACANCY_SKILL_FAIL:
        state.jobDetail.isLoading = false;
        break;

      case types.UPDATE_VACANCY_SKILL_REQUEST:
        state.jobDetail.isLoading = true;
        break;
      case types.UPDATE_VACANCY_SKILL_SUCCESS:
        state.jobDetail.isLoading = false;
        state.jobDetail.vacancySkill = action.payload.data;
        break;
      case types.UPDATE_VACANCY_SKILL_FAIL:
        state.jobDetail.isLoading = false;
        break;

      // Directory
      case types.GET_RECOURSE_SUCCESS:
        state.jobDetail.recourse = action.payload.data;
        break;
      // Update Rating
      case types.UPDADTE_RATING_REQUEST:
        state.jobDetail.isLoading = true;
        break;
      case types.UPDADTE_RATING_SUCCESS:
        state.jobDetail.isLoading = false;
        state.jobDetail.column.candidates = {
          ...state.jobDetail.column.candidates,
          [action.payload.data.candidateId]: {
            ...state.jobDetail.column.candidates[action.payload.data.candidateId],
            rating: action.payload.data.rating,
          },
        };
        break;
      case types.UPDADTE_RATING_FAIL:
        state.jobDetail.isLoading = false;
        break;
      default:
        return;
    }
  });
export default JobDetailReducer;
